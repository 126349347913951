import React, { useContext, Fragment } from "react";

import { AuthContext } from "../auth/AuthProv";

import "../App.css";
import { useHistory } from "react-router-dom";
import StartLogComp from "../components/StartLogComp";
import LogoutB from "../components/LogoutB";
import BottomNavRead from "../components/BottomNavRead";

import TabAdminComp from "../components/TabAdminComp";
import BottomNavigationAdminComp from "../components/BottomNavigationAdminComp";

function Admin({ location }) {
  const getPathname = location.pathname.split("/")[1];

  const { currentUser, isLogged } = useContext(AuthContext);

  const history = useHistory();
  function handleClick() {
    history.push("/");
  }

  const displayThis =
    currentUser.length < 1 ? (
      <StartLogComp />
    ) : currentUser[0] === getPathname && currentUser[1] === "admin" ? (
      <div>
        <Fragment>
          <TabAdminComp path={getPathname} />
          <BottomNavigationAdminComp />
        </Fragment>
      </div>
    ) : currentUser[0] === getPathname && currentUser[1] === "read" ? (
      <div>
        <BottomNavRead path={getPathname} />
      </div>
    ) : currentUser[0] !== getPathname ? (
      <div>
        <StartLogComp />
        <p>
          Sie versuchen sich unter friendlymaze.com/{getPathname}/admin
          einzuloggen. Bitte stellen Sie sicher, dass Sie berechtigt sind, sich
          unter diesm Pfad einzuloggen, beziehungsweise dass die URL korrekt
          ist.
        </p>
        <p>
          Gehen Sie über die Startseite{" "}
          <span className="unterstrich" onClick={handleClick}>
            friendlymaze.com
          </span>{" "}
          um sich in Ihrem Bereich einzuloggen
        </p>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
        }}
      >
        <h1>Loading...</h1>
      </div>
    );
  return (
    <div>
      {isLogged ? (
        displayThis
      ) : (
        <div>
          <StartLogComp />
        </div>
      )}
    </div>
  );
}
export default Admin;
