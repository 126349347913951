import React, { useContext, useEffect } from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import Tab from "@material-ui/core/Tab";
import firebase from "./../firebase";
import AppContext from "../auth/AppContext";
import ListIcon from "@material-ui/icons/List";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

let lengthV;

export default function BottomNavigationAdminComp() {
  const {
    setTab,
    cardData,
    schoolsC,
    loadingC,
    filters,
    filteredAllL,
  } = useContext(AppContext);
  const logOut = () => {
    firebase.auth().signOut();
  };
  const classes = useStyles();

  const filteredArrAll = [];
  if (schoolsC[0] === "start") {
    console.log("bottomnix");
  } else {
    for (let i = 0; i < schoolsC.length; i++) {
      if (
        schoolsC[i].status.includes("z") ||
        schoolsC[i].status.includes("n")
      ) {
        filteredArrAll.push(schoolsC[i]);
      }

      console.log("filteredArr", filteredArrAll);
    }
  }

  return (
    <div className="bottom-navigation">
      <div className="navi-icons">
        <div onClick={() => setTab(1)} className="icon-div">
          <Badge badgeContent={filteredArrAll.length} color="secondary">
            <FastfoodIcon />
          </Badge>
        </div>

        <div onClick={logOut} className="icon-div">
          <ExitToAppIcon />
        </div>
      </div>
    </div>
  );
}
