import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },
}));

export default function Datenschutz({ location }) {
  const classes = useStyles();

  const history = useHistory();
  function handleClick() {
    history.push(`/${location.pathname.split("/")[1]}`);
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Toolbar>
            <ArrowBackIcon onClick={handleClick} />
            <Typography variant="h6" className={classes.title}>
              Datenschutzerklärung
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
      <div className="set-max-width">
        <div className="right-left-margin">
          <h4>Datenschutz</h4>
          <p>
            Wir haben diese Datenschutzerklärung (Fassung 13.01.2021) verfasst,
            um Ihnen gemäß der Vorgaben der{" "}
            <a
              href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=311250159"
              target="_blank"
              rel="noopener noreferrer"
            >
              Datenschutz-Grundverordnung (EU) 2016/679
            </a>{" "}
            zu erklären, welche Informationen wir sammeln, wie wir Daten
            verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher
            dieser Webseite haben.
          </p>
          <p>
            Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr
            technisch klingen, wir haben uns bei der Erstellung jedoch bemüht
            die wichtigsten Dinge so einfach und klar wie möglich zu
            beschreiben.
          </p>
          <h4>Automatische Datenspeicherung</h4>
          <p>
            Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen
            automatisch erstellt und gespeichert, so auch auf dieser Webseite.
          </p>
          <p>
            Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert
            unser Webserver (Computer auf dem diese Webseite gespeichert ist)
            automatisch Daten wie
          </p>
          <ul>
            <li>die Adresse (URL) der aufgerufenen Webseite</li>
            <li>Browser und Browserversion</li>
            <li>das verwendete Betriebssystem</li>
            <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
            <li>
              den Hostname und die IP-Adresse des Geräts von welchem aus
              zugegriffen wird
            </li>
            <li>Datum und Uhrzeit</li>
          </ul>
          <p>in Dateien (Webserver-Logfiles).</p>
          <p>
            In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und
            danach automatisch gelöscht. Wir geben diese Daten nicht weiter,
            können jedoch nicht ausschließen, dass diese Daten beim Vorliegen
            von rechtswidrigem Verhalten eingesehen werden.
          </p>
          <h4>Speicherung persönlicher Daten</h4>
          <p>
            Persönliche Daten, die Sie uns auf dieser Website elektronisch
            übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder
            andere persönlichen Angaben im Rahmen der Übermittlung eines
            Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit dem
            Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck
            verwendet, sicher verwahrt und nicht an Dritte weitergegeben.
          </p>
          <p>
            Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation
            mit jenen Besuchern, die Kontakt ausdrücklich wünschen und für die
            Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und
            Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht
            weiter, können jedoch nicht ausschließen, dass diese Daten beim
            Vorliegen von rechtswidrigem Verhalten eingesehen werden.
          </p>
          <p>
            Wenn Sie uns persönliche Daten per E-Mail schicken; somit abseits
            dieser Webseite; können wir keine sichere Übertragung und den Schutz
            Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten
            niemals unverschlüsselt per E-Mail zu übermitteln.
          </p>
          <p>
            Die Rechtsgrundlage besteht nach{" "}
            <a
              href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=311250159"
              target="_blank"
              rel="noopener noreferrer"
            >
              Artikel 6  Absatz 1 a DSGVO
            </a>{" "}
            (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die
            Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten
            geben. Sie können diesen Einwilligung jederzeit widerrufen; eine
            formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im
            Impressum.
          </p>
          <h4>Rechte laut Datenschutzgrundverordnung</h4>
          <p>
            Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die
            folgende Rechte zu:
          </p>
          <ul>
            <li>Recht auf Berichtigung (Artikel 16 DSGVO)</li>
            <li>
              Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17
              DSGVO)
            </li>
            <li>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
            <li>
              Recht auf Benachrichtigung; Mitteilungspflicht im Zusammenhang mit
              der Berichtigung oder Löschung personenbezogener Daten oder der
              Einschränkung der Verarbeitung (Artikel 19 DSGVO)
            </li>
            <li>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
            <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
            <li>
              Recht, nicht einer ausschließlich auf einer automatisierten
              Verarbeitung — einschließlich Profiling — beruhenden Entscheidung
              unterworfen zu werden (Artikel 22 DSGVO)
            </li>
          </ul>
          <p>
            Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
            Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche
            sonst in einer Weise verletzt worden sind, können Sie sich an die{" "}
            <a
              href="https://www.bfdi.bund.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bundesbeauftragte für den Datenschutz und die Informationsfreiheit
              (BfDI)
            </a>{" "}
            wenden.
          </p>
          <h4>TLS-Verschlüsselung mit https</h4>
          <p>
            Wir verwenden https um Daten abhörsicher im Internet zu übertragen
            (Datenschutz durch Technikgestaltung{" "}
            <a
              href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=311250159"
              target="_blank"
              rel="noopener noreferrer"
            >
              Artikel 25 Absatz 1 DSGVO
            </a>
            ). Durch den Einsatz von TLS (Transport Layer Security), einem
            Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet
            können wir den Schutz vertraulicher Daten sicherstellen. Sie
            erkennen die Benutzung dieser Absicherung der Datenübertragung am
            kleinen Schloßsymbol links oben im Browser und der Verwendung des
            Schemas https (anstatt http) als Teil unserer Internetadresse.
          </p>
          <h4>Google Fonts Datenschutzerklärung</h4>
          <p>
            Auf unserer Website verwenden wir Google Fonts. Das sind die
            Google-Schriften der Firma Google Inc. Für den europäischen Raum ist
            das Unternehmen Google Ireland Limited (Gordon House, Barrow Street
            Dublin 4, Irland) für alle Google-Dienste verantwortlich.
          </p>
          <p>
            Für die Verwendung von Google-Schriftarten müssen Sie sich nicht
            anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine
            Cookies in Ihrem Browser gespeichert. Die Dateien (CSS,
            Schriftarten/Fonts) werden über die Google-Domains
            fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google
            sind die Anfragen nach CSS und Schriften vollkommen getrennt von
            allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben,
            brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während
            der Verwendung von Google Fonts, an Google übermittelt werden.
            Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der
            verwendeten Schriftarten und speichert diese Daten sicher. Wie die
            Datenspeicherung genau aussieht, werden wir uns noch im Detail
            ansehen.
          </p>
          <h3>Was sind Google Fonts?</h3>
          <p>
            Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über
            800 Schriftarten, die 
            <a href="https://de.wikipedia.org/wiki/Google_LLC?tid=311250159">
              Google
            </a>
             seinen Nutzern kostenlos zu Verfügung stellt.
          </p>
          <p>
            Viele dieser Schriftarten sind unter der SIL Open Font
            License veröffentlicht, während andere unter
            der Apache-Lizenz veröffentlicht wurden. Beides sind freie
            Software-Lizenzen.
          </p>
          <h3>Warum verwenden wir Google Fonts auf unserer Webseite?</h3>
          <p>
            Mit Google Fonts können wir auf unserer eigenen Webseite Schriften
            nutzen, doch müssen sie nicht auf unseren eigenen Server hochladen.
            Google Fonts ist ein wichtiger Baustein, um die Qualität unserer
            Webseite hoch zu halten. Alle Google-Schriften sind automatisch für
            das Web optimiert und dies spart Datenvolumen und ist speziell für
            die Verwendung mit mobilen Endgeräten ein großer Vorteil. Wenn Sie
            unsere Seite besuchen, sorgt die niedrige Dateigröße für eine
            schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts.
            Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen
            Browsern, Betriebssystemen und mobilen Endgeräten können zu Fehlern
            führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten
            optisch verzerren. Dank des schnellen Content Delivery Networks
            (CDN) gibt es mit Google Fonts keine plattformübergreifenden
            Probleme. Google Fonts unterstützt alle gängigen Browser (Google
            Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert
            zuverlässig auf den meisten modernen mobilen Betriebssystemen,
            einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir
            verwenden die Google Fonts also, damit wir unser gesamtes
            Online-Service so schön und einheitlich wie möglich darstellen
            können.
          </p>
          <h3>Welche Daten werden von Google gespeichert?</h3>
          <p>
            Wenn Sie unsere Webseite besuchen, werden die Schriften über einen
            Google-Server nachgeladen. Durch diesen externen Aufruf werden Daten
            an die Google-Server übermittelt. So erkennt Google auch, dass Sie
            bzw. Ihre IP-Adresse unsere Webseite besuchen. Die Google Fonts API
            wurde entwickelt, um Verwendung, Speicherung und Erfassung von
            Endnutzerdaten auf das zu reduzieren, was für eine ordentliche
            Bereitstellung von Schriften nötig ist. API steht übrigens für
            „Application Programming Interface“ und dient unter anderem als
            Datenübermittler im Softwarebereich.
          </p>
          <p>
            Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google
            und ist somit geschützt. Durch die gesammelten Nutzungszahlen kann
            Google feststellen, wie gut die einzelnen Schriften ankommen. Die
            Ergebnisse veröffentlicht Google auf internen Analyseseiten, wie
            beispielsweise Google Analytics. Zudem verwendet Google auch Daten
            des eigenen Web-Crawlers, um festzustellen, welche Webseiten
            Google-Schriften verwenden. Diese Daten werden in der
            BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und
            Entwickler nützen das Google-Webservice BigQuery, um große
            Datenmengen untersuchen und bewegen zu können.
          </p>
          <p>
            Zu bedenken gilt allerdings noch, dass durch jede Google Font
            Anfrage auch Informationen wie Spracheinstellungen, IP-Adresse,
            Version des Browsers, Bildschirmauflösung des Browsers und Name des
            Browsers automatisch an die Google-Server übertragen werden. Ob
            diese Daten auch gespeichert werden, ist nicht klar feststellbar
            bzw. wird von Google nicht eindeutig kommuniziert.
          </p>
          <h3>Wie lange und wo werden die Daten gespeichert?</h3>
          <p>
            Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen
            Servern, die hauptsächlich außerhalb der EU angesiedelt sind. Das
            ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten
            zu nutzen. Ein Stylesheet ist eine Formatvorlage, über die man
            einfach und schnell z.B. das Design bzw. die Schriftart einer
            Webseite ändern kann.
          </p>
          <p>
            Die Font-Dateien werden bei Google ein Jahr gespeichert. Google
            verfolgt damit das Ziel, die Ladezeit von Webseiten grundsätzlich zu
            verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften
            verweisen, werden sie nach dem ersten Besuch zwischengespeichert und
            erscheinen sofort auf allen anderen später besuchten Webseiten
            wieder. Manchmal aktualisiert Google Schriftdateien, um die
            Dateigröße zu reduzieren, die Abdeckung von Sprache zu erhöhen und
            das Design zu verbessern.
          </p>
          <h3>
            Wie kann ich meine Daten löschen bzw. die Datenspeicherung
            verhindern?
          </h3>
          <p>
            Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können
            nicht einfach gelöscht werden. Die Daten werden beim Seitenaufruf
            automatisch an Google übermittelt. Um diese Daten vorzeitig löschen
            zu können, müssen Sie den Google-Support auf{" "}
            <a href="https://support.google.com/?hl=de&amp;tid=311250159">
              https://support.google.com/?hl=de&amp;tid=311250159
            </a>{" "}
            kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur,
            wenn Sie unsere Seite nicht besuchen.
          </p>
          <p>
            Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten
            Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein
            Meer an Schriftarten zugreifen und so das Optimum für unsere
            Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden
            Sie auf{" "}
            <a href="https://developers.google.com/fonts/faq?tid=311250159">
              https://developers.google.com/fonts/faq?tid=311250159
            </a>
            . Dort geht zwar Google auf datenschutzrelevante Angelegenheiten
            ein, doch wirklich detaillierte Informationen über Datenspeicherung
            sind nicht enthalten. Es ist relativ schwierig, von Google wirklich
            präzise Informationen über gespeicherten Daten zu bekommen.
          </p>
          <p>
            Welche Daten grundsätzlich von Google erfasst werden und wofür diese
            Daten verwendet werden, können Sie auch auf{" "}
            <a href="https://policies.google.com/privacy?hl=de&amp;tid=311250159">
              https://www.google.com/intl/de/policies/privacy/
            </a>{" "}
            nachlesen.
          </p>
          {/* <h4>Google Fonts Lokal Datenschutzerklärung</h4>
        <p>
          Auf unserer Website nutzen wir Google Fonts der Firma Google Inc. Für
          den europäischen Raum ist das Unternehmen Google Ireland Limited
          (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich. Wir
          haben die Google-Schriftarten lokal, d.h. auf unserem Webserver nicht
          auf den Servern von Google eingebunden. Dadurch gibt es keine
          Verbindung zu Google-Servern und somit auch keine Datenübertragung
          oder Speicherung.
        </p>
        <h3>Was sind Google Fonts?</h3>
        <p>
          Früher nannte man Google Fonts auch Google Web Fonts. Dabei handelt es
          sich um ein interaktives Verzeichnis mit über 800 Schriftarten, die{" "}
          <a href="https://de.wikipedia.org/wiki/Google_LLC?tid=311250159">
            Google
          </a>
           kostenlos bereitstellt. Mit Google Fonts könnte man Schriften nutzen,
          ohne sie auf den eigenen Server hochzuladen. Doch um diesbezüglich
          jede Informationsübertragung zu Google-Servern zu unterbinden, haben
          wir die Schriftarten auf unseren Server heruntergeladen. Auf diese
          Weise handeln wir datenschutzkonform und senden keine Daten an Google
          Fonts weiter.
        </p>
        <p>
          Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten
          Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein
          Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite
          rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf{" "}
          <a href="https://developers.google.com/fonts/faq?tid=311250159">
            https://developers.google.com/fonts/faq?tid=311250159
          </a>
          .
        </p> */}
          <h4>BootstrapCDN Datenschutzerklärung</h4>
          <p>
            Um Ihnen all unsere einzelnen Webseiten (Unterseiten unserer
            Website) auf allen Geräten schnell und sicher ausliefern zu können,
            nutzen wir das Content Delivery Network (CDN) BootstrapCDN des
            amerikanischen Software-Unternehmens StackPath, LLC 2012 McKinney
            Ave. Suite 1100, Dallas, TX 75201, USA.
          </p>
          <p>
            Ein Content Delivery Network (CDN) ist ein Netzwerk regional
            verteilter Server, die über das Internet miteinander verbunden sind.
            Durch dieses Netzwerk können Inhalte, speziell sehr große Dateien,
            auch bei großen Lastspitzen schnell ausgeliefert werden.
          </p>
          <p>
            BootstrapCDN funktioniert so, dass sogenannte
            JavaScript-Bibliotheken an Ihren Browser ausgeliefert werden. Lädt
            nun Ihr Browser eine Datei vom BootstrapCDN herunter, wird Ihre
            IP-Adresse während der Verbindung zum Bootstrap-CDN-Server an das
            Unternehmen StockPath übermittelt.
          </p>
          <p>
            StackPath erwähnt auch in der hauseigenen Datenschutzerklärung, dass
            das Unternehmen aggregierte und anonymisierte Daten von diversen
            Diensten (wie BootstrapCDN) für die Erweiterung der Sicherung und
            für andere StackPath-Dienste und Clients verwenden. All diese Daten
            können aber keine Person identifizieren.
          </p>
          <p>
            Wenn Sie diese Datenübertragung unterbinden wollen, können Sie einen
            JavaScript-Blocker (siehe beispielsweise{" "}
            <a
              href="https://noscript.net/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://noscript.net/
            </a>
            ) installieren oder in Ihrem Browser die Ausführung von
            JavaScript-Codes deaktivieren. Bitte beachten Sie aber, dass dadurch
            die Website nicht mehr das gewohnte Service (wie etwa schnelle
            Ladegeschwindigkeit) bieten kann.
          </p>
          <p>
            StackPath ist aktiver Teilnehmer beim EU-U.S. Privacy Shield
            Framework, wodurch der korrekte und sichere Datentransfer
            persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie
            auf{" "}
            <a
              href="https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&amp;status=Active"
              target="_blank"
              rel="follow noopener noreferrer"
            >
              https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&amp;status=Active
            </a>
            .<br />
            Mehr Informationen zum Datenschutz bei StackPath bzw. BootstrapCDN
            finden Sie auf{" "}
            <a
              href="https://www.bootstrapcdn.com/privacy-policy/?tid=311250159"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.bootstrapcdn.com/privacy-policy/
            </a>
            .
          </p>
          <p>
            Quelle: Erstellt mit dem 
            <a
              title="Datenschutz Generator Deutschland"
              href="https://www.adsimple.de/datenschutz-generator/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Datenschutz Generator
            </a>{" "}
            von AdSimple in Kooperation mit{" "}
            <a
              href="https://www.hashtagmann.de"
              target="_blank"
              rel="noopener noreferrer"
              title=""
            >
              hashtagmann.de
            </a>
          </p>
        </div>
      </div>
    </Fragment>
  );
}
