import React, { useState, Fragment, useContext } from "react";
//import firebase from "../firebase";

import SingleCard from "./SingleCard";
import AppContext from "../auth/AppContext";

function CardsComp(props) {
  const [loading, setLoading] = useState(false);

  const { tab, categors, cardData, current } = useContext(AppContext);
  const cateAmend = `categories${current}`;
  console.log(
    "newArrayuu",

    cardData[cateAmend][0].name
  );
  let newArray = cardData.item.filter(function (el) {
    if (categors === []) {
      return el.category === cardData[cateAmend][0].name;
    } else {
      return el.category === cardData[cateAmend][tab].name;
    }
  });
  console.log("neee", newArray);
  return (
    <Fragment>
      {newArray.map((school) => (
        <div key={school.id}>
          <SingleCard
            loading={loading ? <h1>Loading...</h1> : null}
            schoolP={school}
            orderavail={cardData[cateAmend][tab].orderoption}
          />
        </div>
      ))}
    </Fragment>
  );
}

export default CardsComp;
