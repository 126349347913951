import React, { Fragment, useState, useContext, useEffect } from "react";
import Card from "react-bootstrap/Card";
import "bootstrap/dist/css/bootstrap.min.css";

import OrderComp from "./OrderComp";
import { useAddOrderUpdate } from "../auth/OrderContext";
import AppContext from "../auth/AppContext";
import OrderPopover from "./OrderPopover";
import AllergeneComp from "./AllergeneComp";

function SingleCard(props) {
  const { example, setExample, addedOrders, setAddedOrders } = useContext(
    AppContext
  );
  const [darkTheme, setDarkTheme] = useState(false);

  function toggleTheme() {
    setDarkTheme((prevDarkTheme) => !prevDarkTheme);
  }

  const themeStyles = {
    backgroundColor: addedOrders.includes(props.schoolP) ? "#fff3cd" : null,
  };

  const [formText, setFormText] = useState("");

  const handleClick = () => {
    setAddedOrders((old) => [...old, props.schoolP]);
  };

  function handleDel() {
    let filtered = [];

    filtered = addedOrders.filter(function (value, index, arr) {
      return value !== props.schoolP;
    });

    setAddedOrders(filtered);
  }

  const preiscent =
    props.schoolP.price
      .toString()
      .substr(props.schoolP.price.toString().length - 2).length === 1
      ? "0" +
        props.schoolP.price
          .toString()
          .substr(props.schoolP.price.toString().length - 2)
      : props.schoolP.price
          .toString()
          .substr(props.schoolP.price.toString().length - 2);

  const preiseuro =
    props.schoolP.price
      .toString()
      .substr(0, props.schoolP.price.toString().length - 2) === ""
      ? "0"
      : props.schoolP.price
          .toString()
          .substr(0, props.schoolP.price.toString().length - 2);

  const chosen = () => {
    for (let r = 0; addedOrders > 0 && r < addedOrders.length; r++) {
      if (addedOrders[r].id === props.schoolP.id) {
        setDarkTheme(true);
      } else {
        return;
      }
    }
  };

  return (
    <Fragment>
      <div className="custom-card" style={themeStyles}>
        <div className="card-bu">
          <div className="card-title-class">
            <span>{props.schoolP.title}</span>
            <span className="price-title">{preiseuro + "." + preiscent}</span>
          </div>
          <span className="description-sc">{props.schoolP.description}</span>
          <div className="allergene-neben">
            {props.adminPath || !props.orderavail ? (
              console.log("adminpath")
            ) : (
              <div
                onClick={
                  addedOrders.includes(props.schoolP) ? handleDel : handleClick
                }
              >
                <span>
                  <OrderComp schoolP={props.schoolP} />
                </span>
              </div>
            )}
            {props.schoolP.allergene !== "" ? (
              <AllergeneComp schoolP={props.schoolP} />
            ) : null}
          </div>
        </div>
      </div>
      <hr className="single-hr" />
    </Fragment>
  );
}

export default SingleCard;
