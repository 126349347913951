import React, { useContext } from "react";
import AppContext from "../auth/AppContext";
// import styled from 'styled-components';

// import Button from 'components/common/button';

function Prompt() {
  const { setDefPr } = useContext(AppContext);
  // const [deferredPrompt, setDeferredPrompt] = useState(null);

  const beforeInstallPrompt = (event) => {
    event.preventDefault();
    setDefPr(event);
  };

  React.useEffect(() => {
    window.addEventListener("beforeinstallprompt", beforeInstallPrompt, true);
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        beforeInstallPrompt,
        true
      );
    };
  });

  // function install() {
  //   if (!deferredPrompt) return;
  //   deferredPrompt.prompt();

  //   deferredPrompt.userChoice.then((choiceResult) => {
  //     if (choiceResult.outcome === "accepted") {
  //       // accepted
  //     } else {
  //       // dismissed
  //     }
  //     setDeferredPrompt(null);
  //   });
  // }

  return (
    <div></div>
    // <InstallWrapper visible={deferredPrompt !== null}>
    //   <Button onClick={install}>Install app</Button>
    // </InstallWrapper>
  );
}

// const InstallWrapper = styled.div`
//   padding: 32px;
//   display: ${props => props.visible ? 'block' : 'none'};
// `;

export default Prompt;

// import React, { useEffect, useState } from "react";

// export default function Prompt() {
//   const [showButton, setShowButton] = useState(true);
//   const [prompt, setPrompt] = useState(null);
//   const [clik, setClik] = useState("something");
//   useEffect(() => {
//     const handle_storePrompt = (e) => {
//       e.preventDefault();
//       console.log("immma", e);
//       if (showButton) setPrompt(e);
//     };

//     window.addEventListener("beforeinstallprompt", (e) =>
//       handle_storePrompt(e)
//     );

//     return (_) => {
//       window.removeEventListener("beforeinstallprompt", (e) =>
//         handle_storePrompt(e)
//       );
//     };
//   }, [clik]);

//   const handle_prompt = (_) => {
//     setClik("ele");
//     prompt.prompt();
//     setPrompt(null);
//   };

//   return (
//     <div>
//       <p>{prompt}</p>
//       <button onClick={handle_prompt}>
//         {showButton ? <small>Click to Install</small> : <small>Nothin</small>}
//       </button>
//     </div>
//   );
// }
