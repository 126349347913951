import React, { useState, useContext, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import AppContext from '../auth/AppContext'

function Zubemodal(props) {
  const {
    setFetchComment,
    setCommentId,
    setCommentMap,
    cardData,
    current,
  } = useContext(AppContext)
  const [comment, setComment] = useState('')

  function handleClick() {
    comment !== ''
      ? setCommentMap((commentMap) => {
          return {
            ...commentMap,
            [props.mid]: comment,
          }
        })
      : console.log('leeer')
    props.onHide()
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.mheading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {typeof cardData.languages !== 'undefined' &&
        (cardData.languages[current] === 'Deutsch' ||
          cardData.languages[current] === 'deutsch' ||
          cardData.languages[current] === 'German') ? (
          <input
            className="input-width"
            type="text"
            value={comment}
            placeholder="(Optional) Zubereitungswunsch"
            onChange={(e) => setComment(e.target.value)}
          />
        ) : (
          <input
            className="input-width"
            type="text"
            value={comment}
            placeholder="(Optional) Individual request"
            onChange={(e) => setComment(e.target.value)}
          />
        )}
      </Modal.Body>
      {typeof cardData.languages !== 'undefined' &&
      (cardData.languages[current] === 'Deutsch' ||
        cardData.languages[current] === 'deutsch' ||
        cardData.languages[current] === 'German') ? (
        <Modal.Footer>
          <Button onClick={handleClick}>Hinzufügen</Button>
        </Modal.Footer>
      ) : (
        <Modal.Footer>
          <Button onClick={handleClick}>Add to order</Button>
        </Modal.Footer>
      )}
    </Modal>
  )
}

export default Zubemodal
