import React, { Fragment, useState, useContext, useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import 'bootstrap/dist/css/bootstrap.min.css'
import Collapse from 'react-bootstrap/Collapse'
import Zubemodal from './Zubemodal'

import CancelIcon from '@material-ui/icons/Cancel'
import AppContext from '../auth/AppContext'

import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'

function SingleCardOrder(props) {
  const {
    commentMap,
    handleRemove,
    setCountMap,
    countMap,
    cardData,
    current,
  } = useContext(AppContext)
  const [open, setOpen] = useState(false)
  const [darkTheme, setDarkTheme] = useState(true)
  const [count, setCount] = useState(1)

  function toggleTheme() {
    setDarkTheme((prevDarkTheme) => !prevDarkTheme)
  }

  const themeStyles = {
    backgroundColor: !darkTheme ? null : null,

    padding: 0,
    margin: '1rem',
  }
  const idprop = props.schoolP.id

  const [modalShow, setModalShow] = useState(false)

  function handleCancel() {
    handleRemove(idprop)
    setDarkTheme(!darkTheme)
  }

  const preiscent = props.schoolP.price
    .toString()
    .substr(props.schoolP.price.toString().length - 2)

  const preiseuro = props.schoolP.price
    .toString()
    .substr(0, props.schoolP.price.toString().length - 2)

  useEffect(() => {
    setCountMap((countMap) => {
      return {
        ...countMap,
        [props.schoolP.id]: count,
      }
    })
    console.log('hat ausgeführt', countMap)
  }, [count])

  return (
    <Fragment>
      <Card className="wrapper" style={themeStyles}>
        <Card.Body>
          <div className="card-title-class">
            <Card.Title>{props.schoolP.title}</Card.Title>
            <Card.Title className="price-title">
              {' '}
              {preiseuro + '.' + preiscent}
            </Card.Title>
          </div>
          <Card.Text>{props.schoolP.description}</Card.Text>
          <div className="flexbox-container">
            {`Menge: ${count} `}
            <div className="horizontal-seven"></div>
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button
                aria-label="reduce"
                onClick={() => {
                  setCount(Math.max(count - 1, 1))
                }}
              >
                <RemoveIcon fontSize="small" />
              </Button>
              <Button
                aria-label="increase"
                onClick={() => {
                  setCount(count + 1)
                }}
              >
                <AddIcon fontSize="small" />
              </Button>
            </ButtonGroup>
          </div>
          <div className="seven-abstand"></div>
          <div>
            {typeof cardData.languages !== 'undefined' &&
            (cardData.languages[current] === 'Deutsch' ||
              cardData.languages[current] === 'deutsch' ||
              cardData.languages[current] === 'German') ? (
              <strong className="allergene" onClick={() => setModalShow(true)}>
                Zubereitungswunsch
              </strong>
            ) : (
              <strong className="allergene" onClick={() => setModalShow(true)}>
                Individual request
              </strong>
            )}

            <Zubemodal
              show={modalShow}
              onHide={() => setModalShow(false)}
              mheading={props.schoolP.title}
              mid={props.schoolP.id}
            />
          </div>
          <div>
            {typeof commentMap[idprop] !== 'undefined' ? (
              <div className="category-edit-delete">
                <p>{commentMap[idprop]}</p>
                <CancelIcon onClick={handleCancel} />
              </div>
            ) : (
              ''
            )}
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  )
}

export default SingleCardOrder
