import React from "react";
import firebase from "./../firebase";

function LogoutB() {
  const logOut = () => {
    firebase.auth().signOut();
  };

  return <button onClick={logOut}>Log Out</button>;
}

export default LogoutB;
