import React, { useContext } from "react";
import firebase from "../firebase";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../auth/AuthProv";

import AppContext from "../auth/AppContext";

// ADD FUNCTION
function AddButton(props) {
  const { cate } = useContext(AppContext);
  const {
    setsubmit,
    resetTitle,
    reseteuro,
    resetcent,
    resetdescription,
    resetallergene,
    close,
    open,
  } = props;
  const { isLogged } = useContext(AuthContext);

  const ref = firebase.firestore().collection(`${props.path}`);

  // ADD FUNCTION
  function addSchool(newSchool) {
    setsubmit(true);
    if (
      newSchool.title === "" ||
      props.euro.toString().length > 3 ||
      props.cent.toString().length > 2
    ) {
      console.log("Titell fehlt");
    } else {
      setsubmit(false);
      const insert = (arr, index, newItem) => [
        // part of the array before the specified index
        ...arr.slice(0, index),
        // inserted item
        newItem,
        // part of the array after the specified index
        ...arr.slice(index),
      ];

      ref
        .doc("docname")
        .get()
        .then((doc) => {
          const items = doc.data().item;
          let elementPos =
            props.ganzprev !== "aaa"
              ? items
                  .map(function (x) {
                    return x.id;
                  })
                  .indexOf(props.ganzprev) + 1
              : 0;

          const result = insert(items, elementPos, newSchool);
          ref
            .doc("docname")
            .update({ item: result })
            .then(() => {
              console.log("idpaar", cate[props.position], cate);
              console.log("Erfolgreich gespeichert!");
              resetTitle("");
              reseteuro(0);
              resetcent(0);
              resetdescription("");
              resetallergene("");
              close(!open);
            })
            .catch((err) => {
              console.error(err);
            });
        });
    }
  }

  return (
    <div>
      <button
        onClick={() =>
          addSchool({
            title: props.tiltel,
            price: parseInt(props.euro) * 100 + parseInt(props.cent),
            description: props.description,
            allergene: props.allergene,
            id: uuidv4(),
            added: isLogged.email,
            category: props.catego,
          })
        }
      >
        Submit
      </button>
    </div>
  );
}

export default AddButton;
