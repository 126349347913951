import React, { useState, useContext } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import AppContext from "../auth/AppContext";
import firebase from "../firebase";

export default function RadioComp(props) {
  const { current } = useContext(AppContext);
  const cateAmend = `categories${current}`;
  const ref = firebase.firestore().collection(props.path).doc("docname");
  const [radio, setRadio] = useState(props.school.filter);

  const handleChange = (event) => {
    let radiovar = event.target.value;
    setRadio(radiovar);

    ref.get().then((doc) => {
      let filti = doc.data()[cateAmend].map(function (item) {
        return item === props.school ? 1010 : item;
      });

      console.log("radio", radio);
      let pos = filti
        .map(function (e) {
          return e.name;
        })
        .indexOf(props.school.name);
      filti[pos] = {
        name: props.school.name,
        orderoption: props.school.orderoption,
        filter: radiovar,
      };

      ref.update({ [cateAmend]: filti }).then(() => {
        console.log("erledigt", filti, props.school);
      });
    });
  };
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Filter</FormLabel>
      <RadioGroup
        aria-label="filterconfiguration"
        name="service"
        value={radio}
        onChange={handleChange}
      >
        <FormControlLabel value="service" control={<Radio />} label="Service" />
        <FormControlLabel value="kitchen" control={<Radio />} label="Küche" />
      </RadioGroup>
    </FormControl>
  );
}
