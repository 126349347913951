import React, { useContext, useState, useEffect, Fragment } from "react";
import AppContext from "../../auth/AppContext";
import firebase from "../../firebase";
import moment from "moment";

import OrdersCardGroup from "./OrdersCardGroup";
import ChipComp from "./ChipComp";
import OrdersFilter from "./OrdersFilter";

export default function OrdersAdmin(props) {
  const {
    cardData,
    schoolsC,
    loadingC,
    filters,
    setFilters,
    setFilteredAllL,
    current,
  } = useContext(AppContext);
  const cateAmend = `categories${current}`;
  const [zCats, setZCats] = useState({
    filter: "service",
    name: "Lädt",
    orderoption: false,
  });
  const unsortedDate = schoolsC.map((school) => school.createdAt);
  const sortedArray = unsortedDate.sort((a, b) => moment(b).diff(a));

  const orderedi = schoolsC.sort(function (a, b) {
    return sortedArray.indexOf(a.createdAt) - sortedArray.indexOf(b.createdAt);
  });
  console.log("orderedi", orderedi, sortedArray);
  console.log("was hat schoolsc", schoolsC);
  let myDataArray = () => [...cardData["categories"]];

  // myDataArray.push(...cardData["categories"]);

  // myDataArray.push(...cardData["categories0"]);
  // myDataArray.push(...cardData["categories1"]);
  // myDataArray.push(...cardData["categories2"]);
  // myDataArray.push(...cardData["categories3"]);
  // myDataArray.push(...cardData["categories4"]);

  function newArr() {
    let fuuttt = [];

    fuuttt.push(...cardData["categories0"]);
    fuuttt.push(...cardData["categories1"]);
    fuuttt.push(...cardData["categories2"]);
    fuuttt.push(...cardData["categories3"]);
    fuuttt.push(...cardData["categories4"]);
    return fuuttt;
  }

  let foundValue = newArr().filter((obj) => obj.filter === filters);

  const catsL =
    filters === "all" || filters === "done"
      ? newArr().map((school) => school.name)
      : foundValue.map((school) => school.name);

  console.log(
    foundValue,
    "zgjj",
    catsL,
    newArr().filter((obj) => obj.filter === "service")
  );

  let indexArr = [];

  var p;
  for (p = 0; loadingC === false && p < orderedi.length; p++) {
    var k;
    if (!schoolsC.includes("start")) {
      for (k = 0; k < orderedi[p].orderitem.length; k++) {
        if (
          catsL.includes(orderedi[p].orderitem[k].category) &&
          !indexArr.includes(orderedi[p])
        ) {
          indexArr.push(orderedi[p]);
        }
      }
    }
  }
  console.log("indexArr", indexArr);

  const filteredArrAll = [];
  for (let i = 0; schoolsC[0] !== "start" && i < schoolsC.length; i++) {
    if (schoolsC[i].status.includes("z") || schoolsC[i].status.includes("n")) {
      filteredArrAll.push(schoolsC[i]);
    }

    console.log("filteredArr", filteredArrAll, props.filti, schoolsC);
  }

  const serviceCatObjs = newArr().filter((obj) => obj.filter === "service");
  const serviceCats = serviceCatObjs.map((school) => school.name);

  const sortedServ = [];

  var q;
  for (q = 0; loadingC === false && q < orderedi.length; q++) {
    console.log("hereup", loadingC === false, orderedi.length);
    var u;
    if (!schoolsC.includes("start")) {
      console.log("hereup2", !schoolsC.includes("start"));
      for (u = 0; u < orderedi[q].orderitem.length; u++) {
        console.log(
          "hereup3",
          orderedi[q].orderitem.length,
          orderedi[q].orderitem[u].category,
          serviceCats.includes(orderedi[q].orderitem[u].category),
          serviceCats
        );
        if (
          serviceCats.includes(orderedi[q].orderitem[u].category) &&
          !sortedServ.includes(orderedi[q])
        ) {
          if (orderedi[q].status[u] !== "e") {
            sortedServ.push(orderedi[q]);
            console.log("hierangekomm", sortedServ, orderedi[q].status[u]);
          }
        }
      }
    }
  }

  const kitchenCatObjs = newArr().filter((obj) => obj.filter === "kitchen");
  const kitchenCats = kitchenCatObjs.map((school) => school.name);

  const kitchenArr = [];

  let r;
  for (r = 0; loadingC === false && r < orderedi.length; r++) {
    console.log("hereup", loadingC === false, orderedi.length);
    let m;
    if (!schoolsC.includes("start")) {
      console.log("hereup2", !schoolsC.includes("start"));
      for (m = 0; m < orderedi[r].orderitem.length; m++) {
        console.log(
          "hereup3",
          orderedi[r].orderitem.length,
          orderedi[r].orderitem[m].category,
          kitchenCats.includes(orderedi[r].orderitem[m].category),
          kitchenCats
        );
        if (
          kitchenCats.includes(orderedi[r].orderitem[m].category) &&
          !kitchenArr.includes(orderedi[r])
        ) {
          if (orderedi[r].status[m] !== "e") {
            kitchenArr.push(orderedi[r]);
            console.log("hierkitchen", kitchenArr, orderedi[r].status[m]);
          }
        }
      }
    }
  }

  return (
    <div>
      {loadingC ? (
        <h1>Loading...</h1>
      ) : (
        <Fragment>
          <OrdersFilter
            filterall={filteredArrAll.length}
            filterservice={sortedServ.length}
            filterkitchen={kitchenArr.length}
          />
          <hr />
          <div>
            <ChipComp
              orderedos={indexArr}
              schools={schoolsC}
              filti={filters}
              catlist={catsL}
            />

            <OrdersCardGroup
              orderedos={indexArr}
              catlist={catsL}
              path={props.path}
              schools={schoolsC}
              filti={filters}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}
