import React, { useState, useContext } from "react";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioComp from "./RadioComp";

import AppContext from "../auth/AppContext";
import firebase from "../firebase";

export default function ToggleOrderOption(props) {
  const { current, cardData } = useContext(AppContext);
  const cateAmend = `categories${current}`;
  const ref = firebase.firestore().collection(props.path).doc("docname");
  const [orderToggle, setOrderToggle] = useState(props.school.orderoption);
  const handleChange = (event) => {
    console.log("Wahtsmo", orderToggle);
    let toggleVal = event.target.checked;
    console.log("toggleVal", toggleVal);
    setOrderToggle(toggleVal);
    ref.get().then((doc) => {
      let catergor = doc.data()[cateAmend];

      catergor = catergor.map(function (item) {
        return item === props.school ? 1010 : item;
      });

      let pos = catergor
        .map(function (e) {
          return e.name;
        })
        .indexOf(props.school.name);
      catergor[pos] = {
        name: props.school.name,
        orderoption: toggleVal,
        filter: props.school.filter,
      };

      ref.update({ [cateAmend]: catergor }).then(() => {
        console.log("erledigt", catergor, "props.school", cateAmend);
      });
    });
  };

  return (
    <div>
      <span>Bestellungen aus App</span>
      <br />
      <div className="toggle-order-option">
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              control={<Switch color="primary" />}
              label={props.school.orderoption ? "Aktiv" : "Inaktiv"}
              labelPlacement="start"
              onChange={handleChange}
              checked={props.school.orderoption}
            />
          </FormGroup>
        </FormControl>

        {props.school.orderoption ? (
          <RadioComp path={props.path} school={props.school} />
        ) : null}
      </div>
    </div>
  );
}
