import React from "react";
import firebase from "../firebase";

import DeleteIcon from "@material-ui/icons/Delete";

function DeleteComp(props) {
  const ref = firebase.firestore().collection(`${props.path}`);
  const platzhalt = props.id;
  console.log(platzhalt);
  //DELETE FUNCTION
  function deleteSchool() {
    console.log(platzhalt);
    ref
      .doc("docname")
      .update({
        item: firebase.firestore.FieldValue.arrayRemove({
          title: props.title,
          price: props.price,
          description: props.description,
          allergene: props.allergene,
          id: props.id,
          added: props.adde,
          category: props.catego,
        }),
      })
      .then(() => {
        console.log("Deletedii");
        console.log(platzhalt);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <div>
      <DeleteIcon onClick={() => deleteSchool()} />
    </div>
  );
}

export default DeleteComp;
