import React, { Fragment, useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import "bootstrap/dist/css/bootstrap.min.css";
import DoneIcon from "@material-ui/icons/Done";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import firebase from "../../firebase";
import Grid from "@material-ui/core/Grid";

// import { useAddOrder, useAddOrderUpdate } from "../auth/OrderContext";

//let backRC = "new";

export default function SingleCardOrderAdmin(props) {
  // const orders = useAddOrder();
  // const toggleOrders = useAddOrderUpdate();
  const [newTheme, setnewTheme] = useState(null);
  const themeStyles = {
    backgroundColor: newTheme,
    padding: 0,
    margin: "1rem",
  };

  const ref = firebase
    .firestore()
    .collection(props.path + "-orders")
    .doc(props.oitem.orderid);

  const zuberC = "#35d0ba";
  const erlC = "coral";

  function initialS() {
    // ref.get().then((doc) => {
    //   console.log("wieee oft hier sollte nur einmal");
    //   if (doc.data().status[props.index] === "n") {
    //     console.log("unerledigt");
    //   } else if (doc.data().status[props.index] === "z") {
    //     setnewTheme(zuberC);
    //   } else {
    //     setnewTheme(erlC);
    //   }
    // });
    var result = props.schools.find((obj) => {
      return obj.orderid === props.oitem.orderid;
    });
    if (result.status[props.index] === "n") {
      console.log("unerledigt");
    } else if (result.status[props.index] === "z") {
      setnewTheme(zuberC);
    } else {
      setnewTheme(erlC);
    }
  }

  useEffect(() => {
    initialS();
  }, [props.schools]);

  // function toggleTheme(u) {
  //   if (u === "z") {
  //     setnewTheme("darkseagreen");
  //   } else if (u === "e") {
  //     setnewTheme("coral");
  //   } else {
  //     setnewTheme("");
  //   }
  // }

  // useEffect(() => {
  //   toggleTheme(newTheme)
  // }, [newTheme]);
  // const uidRef = props.oitem.orderitem[props.index];
  // const zubereitet = "zubereitet";
  // const ists = [props.index];
  let statArray = props.oitem.status;

  function zubeClick() {
    var result = props.schools.find((obj) => {
      return obj.orderid === props.oitem.orderid;
    });
    console.log("result", result);
    setnewTheme(zuberC);
    console.log("props.oitem.orderid", props.oitem.orderitem);
    statArray[props.index] = "z";
    ref.update({ status: statArray }).then(() => {
      console.log("zubereitet eingetragen");
    });
  }

  function erlClick() {
    setnewTheme(erlC);
    statArray[props.index] = "e";
    ref.update({ status: statArray }).then(() => {
      console.log("erledigt eingetragen");
    });
  }

  const keyV = props.item.title + props.oitem.createdAt;

  return (props.filti === "done" && props.oitem.status[props.index] === "e") ||
    (props.filti !== "done" &&
      props.oitem.status[props.index] !== "e" &&
      ((props.filti === "service" &&
        props.catlist.includes(props.item.category)) ||
        (props.filti === "kitchen" &&
          props.catlist.includes(props.item.category)) ||
        props.filti === "all")) ? (
    <Fragment>
      <Card className="wrapper" style={themeStyles} key={keyV}>
        <Card.Body>
          <div className="card-title-class">
            <Card.Title>{props.item.title}</Card.Title>
            <Card.Title className="anzahl">{props.item.quantity}x</Card.Title>
          </div>
          <Card.Text>
            <div className="description-sc">{props.item.description}</div>
          </Card.Text>
          {
            <Card.Text>
              {newTheme === zuberC ? (
                <div className="comment-bereit">{props.item.comment}</div>
              ) : newTheme === erlC ? (
                <div className="comment-alternativ">{props.item.comment}</div>
              ) : (
                <div className="comment-farbe">{props.item.comment}</div>
              )}
            </Card.Text>
          }
          <div className="card-title-class">
            <div onClick={zubeClick}>
              <Grid
                container
                direction="column"
                alignItems="center"
                className=""
              >
                <Grid item>
                  <NotificationsActiveIcon fontSize="large" />
                </Grid>
              </Grid>
            </div>

            <div onClick={erlClick}>
              <Grid
                container
                direction="column"
                alignItems="center"
                className=""
              >
                <Grid item>
                  <DoneIcon fontSize="large" />
                </Grid>
              </Grid>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  ) : null;
}
