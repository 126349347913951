import React, { useEffect } from "react";

import TabComp from "../components/TabComp";
import Navi from "../components/Navi";
import BottomNavigation from "../components/BottomNavigation";

function Location({ location }) {
  // useEffect(() => {
  //   let deferredPrompt = "Hallohallo";

  //   window.addEventListener("beforeinstallprompt", function (event) {
  //     console.log("[index] beforeinstallprompt fired");
  //     event.preventDefault();
  //     deferredPrompt = event;
  //     console.log(deferredPrompt);
  //     return false;
  //   });
  //   console.log("deferredPrompt", deferredPrompt);

  //   // eslint-disable-next-line
  // }, []);
  const pathProp = location.pathname.split("/")[1];
  return (
    <div>
      <TabComp path={location.pathname.split("/")[1]} />
      <BottomNavigation path={location.pathname.split("/")[1]} />
    </div>
  );
}

export default Location;
