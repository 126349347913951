import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function SingleChip(props) {
  const fixedTime = moment(props.uppi.createdAt).fromNow();
  const [update, setUpdate] = useState(fixedTime);
  const classes = useStyles();

  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };

  const handleClick = () => {
    console.info("You clicked the Chip.");
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      //assign interval to a variaable to clear it
      setUpdate(fixedTime);
      console.log("updated");
    }, 10000);
    console.log(props.uppi.status.includes("z"), props.uppi.status);
    return () => clearInterval(intervalId); //This is important
  }, []);

  const catArray = props.uppi.orderitem.map((cat) => cat.category);
  console.log("CATLIST", props.catlist);
  const serviceTest = props.catlist.some((r) => catArray.indexOf(r) >= 0);

  //   var arr = [{ id: 1, username: 'fred' },
  //   { id: 2, username: 'bill'},
  //   { id: 3, username: 'ted' }];

  // function userExists(username) {
  //   return arr.some(function(el) {
  //     return el.username === username;
  //   });
  // }

  // console.log(userExists('fred')); // true
  // console.log(userExists('bred')); // false

  // (props.filti === "done" && props.uppi.status.includes("e")) ||
  //   (props.filti !== "done" &&
  //     (props.uppi.status.includes("n") || props.uppi.status.includes("z")) &&
  //     ((props.filti === "service" && serviceTest) ||
  //       (props.filti === "kitchen" && serviceTest) ||
  //       props.filti === "all")) ? (

  return (props.filti === "done" && props.uppi.status.includes("e")) ||
    (props.filti !== "done" && !props.uppi.status.includes("e")) ? (
    <div className={classes.root}>
      {/* <Chip label="vor 3 Minuten | Tisch 6" variant="outlined" />
      <Chip label="Disabled" disabled variant="outlined" />
      <Chip
        avatar={<Avatar>6</Avatar>}
        label="6 Minuten"
        onClick={handleClick}
        variant="outlined"
      />
      <Chip
        avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
        label="Deletable"
        onDelete={handleDelete}
        variant="outlined"
      />
      <Chip
        icon={<FaceIcon />}
        label="Clickable deletable"
        onClick={handleClick}
        onDelete={handleDelete}
        variant="outlined"
      />
      <Chip
        label="Custom delete icon"
        onClick={handleClick}
        onDelete={handleDelete}
        deleteIcon={<DoneIcon />}
        variant="outlined"
      />
      <Chip
        label="Clickable link"
        component="a"
        href="#chip"
        clickable
        variant="outlined"
      /> */}

      <Chip
        style={{
          backgroundColor:
            props.uppi.status.includes("z") && props.filti !== "done"
              ? "#35d0ba"
              : null,
        }}
        avatar={<Avatar>{props.uppi.table}</Avatar>}
        label={update}
        clickable
        onClick={handleClick}
        color="primary"
        onDelete={handleDelete}
        deleteIcon={<DoneIcon />}
        variant="outlined"
      />

      {/* <Chip
        icon={<FaceIcon />}
        label="Primary clickable"
        clickable
        color="primary"
        onDelete={handleDelete}
        deleteIcon={<DoneIcon />}
        variant="outlined"
      />
      <Chip
        label="Deletable primary"
        onDelete={handleDelete}
        color="primary"
        variant="outlined"
      />
      <Chip
        icon={<FaceIcon />}
        label="Deletable secondary"
        onDelete={handleDelete}
        color="secondary"
        variant="outlined"
      /> */}
    </div>
  ) : (props.filti !== "done" && props.uppi.status.includes("z")) ||
    (props.filti !== "done" && props.uppi.status.includes("n")) ? (
    <div className={classes.root}>
      <Chip
        style={{
          backgroundColor:
            props.uppi.status.includes("z") && props.filti !== "done"
              ? "#35d0ba"
              : null,
        }}
        avatar={<Avatar>{props.uppi.table}</Avatar>}
        label={update}
        clickable
        onClick={handleClick}
        color="primary"
        onDelete={handleDelete}
        deleteIcon={<DoneIcon />}
        variant="outlined"
      />
    </div>
  ) : null;
}
