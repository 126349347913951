import React, { useState, useContext } from 'react'
import AppContext from '../auth/AppContext'

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

export default function AllergeneComp(props) {
  const { cardData, current } = useContext(AppContext)
  const [open, setOpen] = useState(false)
  return props.schoolP.allergene !== '' ? (
    <div>
      <div className="allergen-icon">
        {cardData.languages[current] === 'Deutsch' ||
        cardData.languages[current] === 'deutsch' ||
        cardData.languages[current] === 'German' ? (
          <span
            className="allergene allergene-rechts"
            onClick={() => setOpen(!open)}
          >
            Allergene
          </span>
        ) : (
          <span
            className="allergene allergene-rechts"
            onClick={() => setOpen(!open)}
          >
            Allergens
          </span>
        )}
        {!open ? (
          <ArrowForwardIosIcon fontSize="inherit" color="disabled" />
        ) : null}
      </div>
      {open ? (
        <div className="allergene">
          <span>{props.schoolP.allergene}</span>
        </div>
      ) : null}
    </div>
  ) : null
}
