import React, { useContext, Fragment, useEffect, useState } from 'react'
import AppContext from '../auth/AppContext'
import AppBar from '@material-ui/core/AppBar'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import DeleteOrder from './DeleteOrder'
import SingleCardOrder from './SingleCardOrder'
import { useHistory } from 'react-router-dom'
import BestellButton from './BestellButton'
import BestellButtonEnglish from './BestellButtonEnglish'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import OrderPopover from './OrderPopover'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
}))

function OrdersComp({ location }) {
  const { addedOrders, hasOrdered, cardData, current } = useContext(AppContext)
  const history = useHistory()
  function handleClick() {
    history.push(`/${location.pathname.split('/')[1]}`)
  }
  console.log('huuu', addedOrders)

  let commentObject = {}

  function addCommentObjects(input1, input2) {
    commentObject[input1] = input2
  }

  useEffect(() => {
    console.log('ran hzirr')
  }, [addedOrders])

  useEffect(() => {
    console.log('ran hzirr2222', commentObject)
  }, [commentObject])

  const classes = useStyles()

  const [tischnummer, setTischnummer] = useState('')
  const [zahl, setZahl] = useState('')

  return (
    <Fragment>
      <AppBar
        position="fixed"
        color="default"
        style={{ background: '#ffcd3c' }}
      >
        <Toolbar>
          <ArrowBackIcon onClick={handleClick} className="back-arrow" />
          {typeof cardData.languages !== 'undefined' &&
          (cardData.languages[current] === 'Deutsch' ||
            cardData.languages[current] === 'deutsch' ||
            cardData.languages[current] === 'German') ? (
            <Typography variant="h6" className={classes.title}>
              Bestellen
            </Typography>
          ) : (
            <Typography variant="h6" className={classes.title}>
              Order
            </Typography>
          )}
        </Toolbar>
      </AppBar>
      <div className="top-space-div"></div>
      {addedOrders.length > 0 && (
        <div>
          <div className="flexbox-container bestellung-vormerken">
            {typeof cardData.languages !== 'undefined' &&
            (cardData.languages[current] === 'Deutsch' ||
              cardData.languages[current] === 'deutsch' ||
              cardData.languages[current] === 'German') ? (
              <div>
                <TextField
                  id="standard-basic"
                  label="Tischnummer"
                  type="number"
                  value={tischnummer}
                  onChange={(e) => setTischnummer(e.target.value)}
                />
                <div className="space-euro"> </div>
                <div className="space-euro"> </div>
                <div className="flexbox-container">
                  <TextField
                    id="standard-basic"
                    label="Zahl des Tages"
                    type="number"
                    value={zahl}
                    onChange={(e) => setZahl(e.target.value)}
                  />
                  <div className="bestell-popover">
                    <OrderPopover menu={'order'} />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <TextField
                  id="standard-basic"
                  label="Table number"
                  type="number"
                  value={tischnummer}
                  onChange={(e) => setTischnummer(e.target.value)}
                />
                <div className="space-euro"> </div>
                <div className="space-euro"> </div>
                <div className="flexbox-container">
                  <TextField
                    id="standard-basic"
                    label="Number of the day"
                    type="number"
                    value={zahl}
                    onChange={(e) => setZahl(e.target.value)}
                  />
                  <div className="bestell-popover">
                    <OrderPopover menu={'order'} />
                  </div>
                </div>
              </div>
            )}
          </div>
          {typeof cardData.languages !== 'undefined' &&
          (cardData.languages[current] === 'Deutsch' ||
            cardData.languages[current] === 'deutsch' ||
            cardData.languages[current] === 'German') ? (
            <BestellButton
              loc={location.pathname.split('/')[1]}
              resettisch={setTischnummer}
              tisch={tischnummer}
              resetzahl={setZahl}
              zahl={zahl}
            />
          ) : (
            <BestellButtonEnglish
              loc={location.pathname.split('/')[1]}
              resettisch={setTischnummer}
              tisch={tischnummer}
              resetzahl={setZahl}
              zahl={zahl}
            />
          )}
        </div>
      )}
      {addedOrders.length < 1 ? (
        typeof cardData.languages !== 'undefined' &&
        (cardData.languages[current] === 'Deutsch' ||
          cardData.languages[current] === 'deutsch' ||
          cardData.languages[current] === 'German') ? (
          <div className="keine-bestellung-vorhanden">
            <p>{hasOrdered ? `Bestellung ist erfolgreich!` : null}</p>
            <span>Um Bestellungen hinzuzufügen</span>
            <p>
              <strong onClick={handleClick}>hier tippen</strong>
            </p>
          </div>
        ) : (
          <div className="keine-bestellung-vorhanden">
            <p>{hasOrdered ? `We received your order!` : null}</p>
            <span>To make another order</span>
            <p>
              <strong onClick={handleClick}>tap here</strong>
            </p>
          </div>
        )
      ) : (
        addedOrders.map((order) => (
          <div key={order.id} className="set-max-width">
            <div className="flexbox-container">
              <SingleCardOrder schoolP={order} comAdd={addCommentObjects} />
              <div className="delete-btn-cardscompsnap">
                <DeleteOrder objDelete={order} />
              </div>
            </div>
          </div>
        ))
      )}
      <div className="bottom-space-div"></div>
    </Fragment>
  )
}

export default OrdersComp
