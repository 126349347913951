import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },
}));

export default function AGB({ location }) {
  const classes = useStyles();

  const history = useHistory();
  function handleClick() {
    history.push(`/${location.pathname.split("/")[1]}`);
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Toolbar>
            <ArrowBackIcon onClick={handleClick} />
            <Typography variant="h6" className={classes.title}>
              AGB
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
      <div className="set-max-width">
        <div className="right-left-margin">
          <h4>
            ALLGEMEINE GESCHÄFTSBEDINGUNGEN FÜR DIE NUTZER UNSERER APP/WEBSITE
          </h4>
          <p>
            Hinweis: Bei Verwendung des Singulars ist (wenn anwendbar) immer
            auch die Pluralform gemeint. Bei Verwendung der maskulinen oder
            femininen Form ist (wenn anwendbar) immer auch die jeweils andere
            Form gemeint.
          </p>
          <h5>1. ALLGEMEINES</h5>
          <p>
            Manachlux UG (haftungsbeschränkt), Besselstr. 25, 68219 Mannheim,
            Deutschland (nachfolgend „Manachlux“) stellt eine Webseite und App
            zur digitalen Darstellung von Angeboten Bestellung von
            Bewirtungsleistungen und sonstigen Leistungen im Zusammenhang mit
            dem Konsum von Speisen und Getränken
            (nachfolgend „Restaurantangebote“) von mit Manachlux
            zusammenarbeitenden Restaurants, Bars oder sonstigen gastronomischen
            Einrichtungen (nachfolgend „Anbieter“) zur Verfügung, welche über
            die Verwendung einer hierfür bestimmten Anwendungssoftware
            (nachfolgend „Applikation“ oder „App“) für mobile Endgeräte
            (Smartphones und Tabletcomputer) und/oder entsprechende
            Internetseiten (beispielsweise https://www.friendlymaze.com/)
            genutzt wird („Applikation“, „App“ und die Website
            https://www.friendlymaze.com/ nachfolgend
            als „App“ zusammengefasst). Nutzer der App
            (nachfolgend „Sie“ oder „Nutzer“) können über die App
            Restaurantangebote innerhalb der Lokalitäten eines Anbieters ansehen
            und (falls vom Anbieter freigeschaltet) bestellen. Dabei entsteht
            ein Vertrag über die Restaurantangebote ausschließlich zwischen dem
            Nutzer und dem jeweiligen Anbieter. Die Bestellung über die App
            sowie die Auslieferung der Bestellung durch den Anbieter erfolgen –
            wenn nicht ausdrücklich anders in der App beschrieben – innerhalb
            des gastronomischen Bereiches (wie durch den Anbieter definiert).
            Manachlux bietet selbst keine Restaurantangebote an und betreibt
            keine Restaurants und bietet auch keine sonstigen gastronomischen
            Leistungen an. Manachlux ist auch nicht verantwortlich für die auf
            der Website des Anbieters und/oder innerhalb der App aufgeführten
            Restaurantangebote des Anbieters.
          </p>
          <h5>2. GELTUNGSBEREICH DIESER AGB</h5>
          <p>
            2.1. Die vorliegenden Allgemeinen Geschäftsbedingungen
            (nachfolgend „AGB“) gelten für sämtliche Leistungen von Manachlux,
            die im Zusammenhang mit der Verwendung der App stehen. Sie sind
            Bestandteil jeder diesbezüglich entstehenden Vertragsbeziehung
            zwischen den Nutzern und Manachlux, die spätestens mit der Nutzung
            der Services (wie in Ziffer 5.1. definiert) beginnt; der Nutzer wird
            – abhängig von der konkreten Nutzung der App – zu dem entsprechend
            relevanten Zeitpunkt um seine Zustimmung zu den AGB gebeten. Die AGB
            gelten insbesondere für die in Ziffer 5.1. näher bezeichneten und
            definierten Services, Informationen und/oder Empfehlungen, die Ihnen
            durch die App zur Verfügung gestellt werden.
            <br />
            Diese AGB gelten auch für alle zukünftigen Leistungen oder Angebote
            von Manachlux, ohne dass diese AGB in jedem Einzelfall gesondert
            vereinbart werden müssen. Sofern nichts anderes vereinbart ist,
            gelten diese AGB in der bei der jeweiligen Inanspruchnahme der
            Leistungen und Angebote von Manachlux gültigen Fassung.
          </p>
          <p>
            2.2. Manachlux kann diese AGB jederzeit ändern. Sollten Sie die App
            weiterhin benutzen, nachdem Manachlux die AGB in geänderter Fassung
            mitgeteilt hat, so erklären Sie sich mit den vorgenommenen
            Änderungen einverstanden, selbst wenn Sie die Kenntnisnahme der
            Änderungen nicht ausdrücklich bestätigen. Prozessänderungen und
            Änderungen der AGB, die Sie vorbringen, sind ohne ausdrückliche,
            mindestens in Textform erfolgende Zustimmung von Manachlux
            unwirksam.
            <p>
              2.3. Für das jeweilige Vertragsverhältnis zwischen Manachlux und
              den Nutzern gelten ausschließlich diese AGB. Abweichende,
              entgegenstehende oder ergänzende Allgemeine Geschäftsbedingungen
              des Nutzers finden keine Anwendung, sofern nicht Manachlux ihrer
              Geltung ausdrücklich und mindestens in Textform zugestimmt hat.
              Eines gesonderten Widerspruchs oder Hinweises auf die
              ausschließliche Geltung dieser AGB bedarf es nicht. Auch
              vorbehaltliche Leistungen durch Manachlux stellen keine
              stillschweigende oder sonstige Zustimmung zu abweichenden
              Allgemeinen Geschäftsbedingungen dar.
            </p>
            <h5>3. ANWENDUNGSSOFTWARE (DIE „APP“)</h5>
            <p>
              3.1. Manachlux ermöglicht den Nutzern die Benutzung der App zwecks
              Bestellung und Informationen im Zusammenhang mit
              Restaurantangeboten verschiedener Anbieter. Manachlux stellt den
              Nutzern hierfür die App kostenlos auf der jeweiligen Unterseite
              des Anbieters auf der Website www.friendlymaze.com zur Verfügung.
            </p>
            <p>3.2. Die Nutzung der App ist für den Nutzer kostenlos.</p>
            <p>
              3.3. Sie dürfen keinen Teil der von Manachlux zur Verfügung
              gestellten Anwendungssoftware kopieren, verändern, verteilen,
              verkaufen oder vermieten. Auch dürfen Sie die Software nicht
              zurückentwickeln ( Reverse Engineering) oder versuchen, den
              Quellcode zu extrahieren, sofern derartige Einschränkungen nicht
              gesetzlich untersagt sind oder hierfür unsere schriftliche
              Einwilligung vorliegt.
            </p>
            <p>
              3.4. Als Nutzer sind Sie dafür verantwortlich, dass Sie über die
              notwendige Hard- und Software verfügen und mit dem Internet
              verbunden sind, um die App nutzen zu können. Ebenso haben Sie
              sicherzustellen, dass die für den Betrieb der App notwendigen
              Systemeinstellungen bei Ihrer Hard- und Software gewählt wurden.
              Während des Zugriffs und/oder der Nutzung der App können Daten-
              sowie Verbindungsentgelte und -gebühren bspw. Ihres mobilen
              Netzwerkanbieters anfallen, für deren Entrichtung Sie
              verantwortlich sind.
            </p>
            <h5>4. NUTZUNG</h5>
            <p>
              4.1 Sie sichern zu, dass es sich bei Ihnen um eine geschäftsfähige
              Einzelperson handelt, so dass Sie einen bindenden Vertrag
              abschließen können. Die Nutzung der App setzt voraus, dass Sie
              unserer Datenschutzerklärung, abrufbar unter 
              <a
                href="https://www.friendlymaze.com/test/datenschutzerklaerung"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.friendlymaze.com/test/datenschutzerklaerung
              </a>
              , zugestimmt haben.
            </p>
            <p>
              4.2. Manachlux ist berechtigt, die in der App hinterlegten oder
              Manachlux anderweitig seitens der Nutzer übermittelten Daten,
              Datenbanken und Inhalte einzusehen und an Dritte weiterzugeben,
              sofern Manachlux hierzu gesetzlich oder durch behördliche
              Anweisung verpflichtet ist, dies zur Abwicklung der im
              Zusammenhang mit bzw. über die App abgeschlossenen Verträge
              erforderlich ist, der Nutzer zuvor seine Zustimmung erklärt hat
              oder der Nutzer einen über die App geschlossenen Vertrag zwischen
              ihm und einem Anbieter nicht erfüllt. Manachlux wird hierbei die
              einschlägigen datenschutzrechtlichen und sonstigen
              Rechtsvorschriften wahren.
            </p>
            <p>
              4.3. Manachlux ist jederzeit berechtigt, die Nutzung die App ohne
              Angabe von Gründen zu verweigern.
            </p>
            <h5>5. SERVICES IN DER APP</h5>
          </p>
          <p>
            5.1. Über die App können die Nutzer bei Anbietern Menüinformationen
            abrufen sowie Restaurantangebote bestellen (zusammenfassend
            die „Services“). Welche Services in einer Lokalität bestehen,
            entscheidet ausschließlich der jeweilige Anbieter. Möglich ist die
            Verfügbarkeit einzelner Services, sämtlicher Services sowie eine
            Kombination verschiedener Services. Die Anbieter entscheiden daher
            auch, ob die Möglichkeit besteht Bestellungen zu tätigen.
          </p>
          <p>
            5.2 Die App bietet die Nutzung der Services an, wenn sich der Nutzer
            innerhalb des gastronomischen Bereiches – wie durch den Anbieter
            definiert – befindet.
          </p>
          <p>
            5.3. Die App kann Empfehlungen zu bestimmten Restaurantangeboten des
            Anbieters (bspw. aktuelle Tagesangebote, Happy Hour-Angebote,
            Specials u.ä.) enthalten, sofern der Anbieter diese Services für
            seine Lokalität anbietet.
          </p>
          <p>
            5.4. Manachlux ist nicht verpflichtet, den Nutzern Services
            anzubieten. Manachlux kann jederzeit nach eigenem Ermessen und ohne
            Haftung alle Services ganz oder teilweise einstellen, aussetzen,
            anpassen, ändern oder einschränken.
          </p>
          <p>
            5.5. Sie sind nicht verpflichtet, die angebotenen Services zu
            nutzen; Manachlux ist ebenfalls nicht verpflichtet, Ihnen sämtliche
            oder einzelne Services bei bestimmten Anbietern anzubieten oder
            erfolgreich einen von Ihnen ausgewählten Service zu erbringen
            (insbesondere Bestellungen zu tätigen).
          </p>
          <h5>
            6. VERTRAGSBEZIEHUNG ZWISCHEN KUNDE UND ANBIETER, ABWICKLUNG,
            ZAHLUNG
          </h5>
          <p>
            6.1. Der Bewirtungsvertrag und/oder ein sonstiger Vertrag über oder
            im Zusammenhang mit der Wahrnehmung von Restaurantangeboten eines
            Anbieters kommt ausschließlich zwischen dem Nutzer und dem Anbieter
            zustande. Die Restaurantangebote werden vom Anbieter in eigenem
            Namen und auf eigene Rechnung erbracht, jedenfalls weder im Namen
            noch auf Rechnung von Manachlux. Die Anbieter handeln in keiner
            Weise für Manachlux. Manachlux haftet dem Nutzer gegenüber
            insbesondere nicht für gesundheitliche Beeinträchtigungen jeglicher
            Art, die auf Restaurantangebote des Anbieters zurückzuführen sind,
            beispielsweise aufgrund verdorbener Ware oder Allergenen.
          </p>
          <p>
            6.2. Für den Inhalt und die Gestaltung der Restaurantangebote wie
            auch für die ordnungsgemäße Beschaffenheit der bestellten Waren und
            die ordentliche Ausführung der Dienstleistungen ist alleine der
            jeweilige Anbieter verantwortlich. Im Hinblick auf die bestellten
            Restaurantangebote ist ausschließlich der jeweilige Anbieter zur
            Erfüllung verpflichtet. Ferner liegt es ausschließlich im Ermessen
            des Anbieters, mit Ihnen einen Bewirtungs- und/oder sonstigen
            Vertrag abzuschließen oder nicht.
          </p>
          <p>
            6.3. Der Nutzer ist für die Bezahlung der in Anspruch genommenen
            Restaurantangebote des Anbieters verantwortlich. Die Preise werden
            in der Regel in der App angezeigt. Für die Richtigkeit der Preise
            ist der Anbieter verantwortlich.
          </p>
          <p>
            6.4. Die App dient lediglich als Hilfsmittel bei der Anbahnung und
            Abwicklung des zwischen dem Nutzer und dem Anbieter zustande
            kommenden Vertragsverhältnisses
          </p>
          <h5>7. DATENSCHUTZ</h5>
          <p>
            7.1. Die Erhebung und Bearbeitung von Personendaten im Rahmen der
            App und in Verbindung mit einzelnen in Anspruch genommenen Services
            erfolgt gemäß dieser AGB sowie gemäß unserer Datenschutzerklärung,
            abrufbar unter {" "}
            <a
              href="https://www.friendlymaze.com/test/datenschutzerklaerung"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.friendlymaze.com/test/datenschutzerklaerung
            </a>
            , sowie unter Berücksichtigung sämtlicher einschlägiger
            Datenschutzbestimmungen.
          </p>
          <p>
            7.2. Sie können die App nur nutzen, wenn Sie Ihre ausdrückliche
            Zustimmung zu unserer Datenschutzerklärung, abrufbar unter{" "}
            <a
              href="https://www.friendlymaze.com/test/datenschutzerklaerung"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.friendlymaze.com/test/datenschutzerklaerung
            </a>
            , erteilt haben.
          </p>
          <h5>8. GEHEIMHALTUNG, VERTRAULICHKEIT UND WERBUNG</h5>
          <p>
            8.1. Manachlux wird die im Rahmen der App preisgegebenen
            Informationen vertraulich behandeln und – vorbehaltlich Ziffer 4.2.
            und der nachfolgenden Ziffer 8.2. – nicht an Dritte weitergeben.
          </p>
          <p>
            8.2. In Ausnahme zu Ziffer 8.1. gibt Manachlux Informationen über
            den Nutzer an Dritte weiter, sofern dies zur ordnungsgemäßen
            Erbringung der von Manachlux angebotenen Services oder zur
            Abwicklung eines zwischen dem Nutzer und einem Anbieter
            geschlossenen Vertrags erforderlich ist.
          </p>
          <p>
            8.3. Über die App kann der Nutzer nicht personifizierte Werbung
            sowie anderweitige Informationen von Dritten erhalten, ohne dass
            hierfür die ausdrückliche vorherige Zustimmung des Nutzers
            erforderlich ist. Sie erkennen an, dass diesbezüglich andere
            Allgemeine Geschäftsbedingungen und Datenschutzerklärungen zur
            Anwendung gelangen können. Manachlux ist nicht für irgendwelche
            Waren, Leistungen oder Inhalte von Dritten verantwortlich oder
            haftbar.
          </p>
          <h5>9. HAFTUNG, AUSSCHLUSS VON ZUSICHERUNGEN UND GARANTIEN</h5>
          <p>
            9.1. Manachlux haftet gegenüber Nutzern und Anbietern nur bei
            Vorsatz oder grober Fahrlässigkeit. Unberührt hiervon bleibt die
            Haftung für Schäden aus der Verletzung des Lebens, des Körpers oder
            der Gesundheit. Bei fahrlässig verursachten Sach- und
            Vermögensschäden haftet Manachlux nur bei der Verletzung einer
            wesentlichen Vertragspflicht, jedoch der Höhe nach beschränkt auf
            die bei Vertragsschluss vorhersehbaren und vertragstypischen
            Schäden. Wesentliche Vertragspflichten sind solche, deren Erfüllung
            den Vertrag prägt und auf die Anbieter und Nutzer vertrauen dürfen.
            Gesetzliche Gewährleistungsansprüche des Nutzers werden durch diese
            Haftungsbegrenzung nicht berührt.
          </p>
          <p>
            9.2. Manachlux haftet nicht für die von den Anbietern erbrachten
            gastronomischen Leistungen oder sonstige von Dritten durchgeführten
            Leistungen. Insbesondere übernimmt Manachlux keinerlei Garantie oder
            Gewährleistung im Hinblick auf die Beschaffenheit der von den
            Anbietern angebotenen gastronomischen Leistungen.
          </p>
          <p>
            9.3. Manachlux macht hinsichtlich der App und den einzelnen Services
            keinerlei Zusicherungen und leistet diesbezüglich keine Garantie
            oder sonstige Gewähr. Jegliche Gewährleistung von Manachlux wird
            hiermit ausgeschlossen.
          </p>
          <p>
            9.4. Sie verpflichten sich, Manachlux, deren Tochtergesellschaften,
            Lizenzgeber sowie Organe, Geschäftsführer, Mitarbeiter und
            Auftragnehmer freizustellen und schadlos zu halten gegenüber allen
            Forderungen, Ansprüchen, Kosten, Schäden, Verlusten,
            Haftungsansprüchen (inklusive Anwaltsgebühren und -kosten), die sich
            aus oder in Verbindung mit Ihrer Verletzung oder Ihrem Verstoß gegen
            irgendeine Bestimmung dieser AGB oder jegliche anwendbaren
            gesetzlichen Bestimmungen, Ihrer Verletzung von Rechten Dritter,
            einschließlich der Anbieter, oder anderweitig aus oder im
            Zusammenhang mit Ihrer Nutzung der App und/oder einzelner Services
            ergeben.
          </p>
          <h5>10. ALTERNATIVE STREITBEILEGUNG</h5>
          <p>
            10.1. Die EU-Kommission stellt im Internet unter folgendem Link eine
            Plattform zur Online-Streitbeilegung zur Verfügung: 
            <a
              href="https://ec.europa.eu/consumers/ord"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://ec.europa.eu/consumers/ord
            </a>
            . Diese Plattform dient als Anlaufstelle zur außergerichtlichen
            Beilegung von Streitigkeiten aus Online-Kauf- oder
            Dienstleistungsverträgen, an denen ein Verbraucher beteiligt ist.
          </p>
          <p>
            10.2 Manachlux ist zur Teilnahme an einem Streitbeilegungsverfahren
            vor einer Verbraucherschlichtungsstelle weder verpflichtet noch
            bereit.
          </p>
          <h5>11. ANWENDBARES RECHT UND GERICHTSSTAND</h5>
          <p>
            11.1. Für das Vertragsverhältnis zwischen den Nutzern und Manachlux
            gilt das Recht der Bundesrepublik Deutschland unter Ausschluss der
            Vorschriften des Internationalen Privatrechts und des UN-Kaufrechts.
          </p>
          <p>
            11.2. Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang
            mit dem Vertragsverhältnis zwischen dem Nutzer und Manachlux
            (einschließlich solcher über das Bestehen oder die Beendigung des
            Vertragsverhältnisses) ist für den Fall, dass der Nutzer ein
            Kaufmann, eine juristische Person des öffentlichen Rechts oder ein
            öffentlich-rechtliches Sondervermögen ist oder zwar Verbraucher ist,
            aber keinen allgemeinen Gerichtsstand in Deutschland hat oder seinen
            Wohnsitz nach Vertragsschluss ins Ausland verlegt oder der Wohnsitz
            des Nutzers zum Zeitpunkt der Klageerhebung nicht zu ermitteln ist,
            der Sitz von Manachlux. <p>Mannheim, 16. Dezember 2020</p>
          </p>
        </div>
      </div>{" "}
    </Fragment>
  );
}
