import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import firebase from "../firebase";
import AppContext from "../auth/AppContext";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

function BestellButton(props) {
  const [message, setMessage] = useState("");
  const [fehler, setFehler] = useState("");
  const {
    addedOrders,
    setAddedOrders,
    commentMap,
    setHasOrdered,
    cardData,
    countMap,
  } = useContext(AppContext);
  const ref = firebase.firestore().collection(props.loc + "-orders");
  function bestellF() {
    const uid = uuidv4();
    ref
      .doc(uid)
      .set({
        coll: props.loc + "-orders",
        crtd: firebase.firestore.FieldValue.serverTimestamp(),
        createdAt: new Date().toISOString(),
        orderid: uid,
        orderitem: addedOrders.map((order) => ({
          title: order.title,
          description: order.description,
          category: order.category,
          comment:
            typeof commentMap[order.id] !== "undefined"
              ? commentMap[order.id]
              : "",
          id: order.id,

          quantity:
            typeof countMap[order.id] !== "undefined" ? countMap[order.id] : 1,
        })),
        table: props.tisch,
        status: Array.from(Array(addedOrders.length), (_, i) => "n"),
      })
      .then(() => {
        setFehler("");
        setMessage("Erfolgreich abgeschickt");
        setAddedOrders([]);
        setHasOrdered(true);
        props.resettisch();
        props.resetzahl();
        console.log("Bestellung abgeschickt");
        console.log(moment("2020-10-12T19:50:52.942Z").fromNow());
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function valida() {
    if (props.tisch === "") {
      setFehler("Die Bestellung konnte nicht abgeschickt werden");
      setMessage("Bitte geben Sie die Nummer Ihres Tisches ein");
    } else if (props.zahl === cardData.zahl) {
      setFehler("Die Bestellung konnte nicht abgeschickt werden");
      setMessage(
        "Bitte geben Sie zur Validierung die 'Zahl des Tages' ein (3-stellig)"
      );
    } else if (props.zahl !== cardData.zahl) {
      setFehler("Die Bestellung konnte nicht abgeschickt werden");
      setMessage("Die eingegebene Zahl des Tages ist nicht korrekt.");
    }
  }
  return (
    <div>
      <div className="bestell-button">
        <Button
          variant="outline-success"
          onClick={
            props.tisch !== "" && props.zahl === cardData.zahl
              ? bestellF
              : valida
          }
        >
          Bestellung abschicken
        </Button>{" "}
      </div>
      {fehler !== "" || message !== "" ? (
        <div>
          <div className="seven-abstand"></div>
          <div>
            {" "}
            <p className="order-fehler-p">{fehler}</p>
            <p className="order-fehler-p">{message}</p>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default BestellButton;
