import React, { Fragment } from "react";
import SingleCardOrderAdmin from "./SingleCardOrderAdmin";

export default function OrdersSingleGroup(props) {
  return (
    <Fragment>
      {props.oitem.orderitem.map((item, index) => (
        <div key={item.id + props.oitem.createdAt}>
          <SingleCardOrderAdmin
            item={item}
            index={index}
            oitem={props.oitem}
            path={props.path}
            schools={props.schools}
            filti={props.filti}
            catlist={props.catlist}
          />
        </div>
      ))}
    </Fragment>
  );
}
