import React, { useContext, useState, useEffect } from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import Badge from "@material-ui/core/Badge";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import firebase from "./../firebase";
import AppContext from "../auth/AppContext";
import ListIcon from "@material-ui/icons/List";
import Box from "@material-ui/core/Box";
import OrdersAdmin from "./orderadmin/OrdersAdmin";
import KontaktReadView from "./KontaktReadView";

function TabPanel(props) {
  const { children, value, index } = props;

  return <div>{value === index && <div>{children}</div>}</div>;
}

export default function BottomNavRead(props) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    cardData,
    schoolsC,
    setCardData,
    setSchoolsC,
    setLoadingC,
  } = useContext(AppContext);

  const ref = firebase.firestore().collection(props.path).doc("docname");
  const refO = firebase.firestore().collection(props.path + "-orders");

  const date = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const unsubscribeO = refO
      .where("crtd", ">", date)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          let myDataArray = [];
          snapshot.forEach((doc) => myDataArray.push({ ...doc.data() }));
          setSchoolsC(myDataArray);
          console.log("date", myDataArray);
          setLoadingC(false);
        } else {
          console.log("emptyr");
          setLoadingC(false);
        }
      });

    const unsubscribe = ref.onSnapshot(function (doc) {
      setCardData(doc.data());
      console.log("cardData", cardData);
      setLoading(false);
    });

    return () => {
      unsubscribeO();
      unsubscribe();
    };
  }, []);

  const logOut = () => {
    firebase.auth().signOut();
  };

  const filteredArrAll = [];
  if (schoolsC[0] === "start") {
    console.log("bottomnix");
  } else {
    for (let i = 0; i < schoolsC.length; i++) {
      if (
        schoolsC[i].status.includes("z") ||
        schoolsC[i].status.includes("n")
      ) {
        filteredArrAll.push(schoolsC[i]);
      }

      console.log("filteredArr", filteredArrAll);
    }
  }

  return (
    <div>
      {loading ? (
        <p> Loading..</p>
      ) : (
        <div className="set-max-width">
          <OrdersAdmin path={props.path} />
        </div>
      )}

      <div className="bottom-navigation">
        <div onClick={logOut} className="centering">
          <ExitToAppIcon />
        </div>
      </div>
    </div>
  );
}
