import React, { Fragment } from "react";
import OrdersSingleGroup from "./OrdersSingleGroup";
import OrderCompHeadline from "./OrderCompHeadline";

export default function OrdersCardGroup(props) {
  return (
    <Fragment>
      {props.orderedos.map((school) => (
        <div key={school.createdAt}>
          <OrderCompHeadline uppi={school} filti={props.filti} />
          <OrdersSingleGroup
            oitem={school}
            catlist={props.catlist}
            filti={props.filti}
            path={props.path}
            schools={props.schools}
          />
        </div>
      ))}
    </Fragment>
  );
}
