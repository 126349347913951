import React, { Fragment, useState } from "react";
import firebase from "../firebase";

const StartLogComp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [wrongEP, setWrongEP] = useState(false);

  const [open, setOpen] = useState(false);
  const [reg, setReg] = useState(false);

  const register = () => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        resetInput();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const login = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        resetInput();
        setWrongEP(false);
      })
      .catch((err) => {
        console.error(err);
        console.log("Binn jetzt im Fehler");
        setWrongEP(true);
      });
  };

  const resetInput = () => {
    setEmail("");
    setPassword("");
  };

  function handleReg() {
    setReg(true);
    setOpen(false);
  }

  function handleLog() {
    setOpen(true);
    setReg(false);
  }

  return (
    <Fragment>
      <div className="login-box">
        {!open ? (
          <div className="ten-margin">
            {!reg ? (
              <button onClick={() => setOpen(!open)}>Login</button>
            ) : null}
          </div>
        ) : (
          <div>
            <div>
              <h3 onClick={() => setOpen(!open)}>Login</h3>
            </div>
            <div className="inputBox ten-margin">
              <input
                className="inputBox ten-margin"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email"
              />{" "}
              <br />
              <input
                className="inputBox ten-margin"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="password"
              />
              <br />
              <button className="ten-margin" onClick={login}>
                Login
              </button>
              <br />
              <span className="ten-margin">
                Sie haben noch keinen Login?{" "}
                <div className="unterstrich" onClick={handleReg}>
                  Registrieren
                </div>
              </span>
            </div>
            {wrongEP ? (
              <p>Login fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben</p>
            ) : null}
          </div>
        )}
        {reg ? (
          <div>
            <h3 className="ten-margin">Registrieren</h3>
            <div className="inputBox ten-margin">
              <input
                className="inputBox ten-margin"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email"
              />{" "}
              <br />
              <input
                className="inputBox ten-margin"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="password"
              />
              <br />
              <p>
                Bitte beachten Sie, dass Ihnen Bearbeitungs- und andere
                Funktionen erst zur Verfügung stehen, wenn wir Sie einem Projekt
                zugeordnet haben!
                <br />
                Kontaktieren Sie uns dafür einfach.
              </p>
              <button className="ten-margin" onClick={register}>
                Registrieren
              </button>
              <p>
                Sie sind bereits registriert?{" "}
                <div className="unterstrich" onClick={handleLog}>
                  Login
                </div>
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default StartLogComp;
