import React, { useContext, useState } from "react";

const OrderContext = React.createContext();
const OrderUpdateContext = React.createContext();

export function useAddOrder() {
  return useContext(OrderContext);
}

export function useAddOrderUpdate() {
  return useContext(OrderUpdateContext);
}

export function OrderProvider({ children }) {
  const [orders, setOrders] = useState(false);

  function toggleOrders() {
    setOrders((prevDarkTheme) => !prevDarkTheme);
  }

  return (
    <OrderContext.Provider value={orders}>
      <OrderUpdateContext.Provider value={toggleOrders}>
        {children}
      </OrderUpdateContext.Provider>
    </OrderContext.Provider>
  );
}
