import React, { useState, useContext } from "react";
import AppContext from "../auth/AppContext";
import TextField from "@material-ui/core/TextField";

import firebase from "../firebase";

export default function ZahlComp(props) {
  const { cardData } = useContext(AppContext);
  const ref = firebase.firestore().collection(props.path).doc("docname");
  const [newZahl, setNewZahl] = useState("");
  const [message, setMessage] = useState("");

  function handleClick() {
    if (newZahl.length === 3) {
      ref.update({ zahl: newZahl }).then(setNewZahl(""));
      setMessage("");
    } else {
      setMessage("Die Zahl muss 3-stellig sein.");
    }
  }

  return (
    <div>
      <p>Aktuelle Zahl des Tages: {cardData.zahl}</p>

      <TextField
        id="standard-basic"
        label="Neue Zahl des Tages"
        type="number"
        value={newZahl}
        onChange={(e) => setNewZahl(e.target.value)}
        variant="outlined"
      />
      <button className="zahl-button" onClick={handleClick}>
        Speichern
      </button>
      <p>{message}</p>
      <hr />
    </div>
  );
}
