import React, { Fragment, useState, useContext, useEffect } from "react";
import Card from "react-bootstrap/Card";
import "bootstrap/dist/css/bootstrap.min.css";

import OrderComp from "./OrderComp";
import { useAddOrderUpdate } from "../auth/OrderContext";
import AppContext from "../auth/AppContext";
import OrderPopover from "./OrderPopover";
import AllergeneComp from "./AllergeneComp";

function SingleCardAdmin(props) {
  const { example, setExample, addedOrders, setAddedOrders } = useContext(
    AppContext
  );
  const [darkTheme, setDarkTheme] = useState(false);

  function toggleTheme() {
    setDarkTheme((prevDarkTheme) => !prevDarkTheme);
  }

  const themeStyles = {
    backgroundColor: addedOrders.includes(props.schoolP) ? "#fff3cd" : null,

    padding: 0,
    margin: "0.5rem",
  };

  const [formText, setFormText] = useState("");

  const handleClick = () => {
    setAddedOrders((old) => [...old, props.schoolP]);
  };

  function handleDel() {
    let filtered = [];

    filtered = addedOrders.filter(function (value, index, arr) {
      return value !== props.schoolP;
    });

    setAddedOrders(filtered);
  }

  const preiscent =
    props.schoolP.price
      .toString()
      .substr(props.schoolP.price.toString().length - 2).length === 1
      ? "0" +
        props.schoolP.price
          .toString()
          .substr(props.schoolP.price.toString().length - 2)
      : props.schoolP.price
          .toString()
          .substr(props.schoolP.price.toString().length - 2);

  const preiseuro =
    props.schoolP.price
      .toString()
      .substr(0, props.schoolP.price.toString().length - 2) === ""
      ? "0"
      : props.schoolP.price
          .toString()
          .substr(0, props.schoolP.price.toString().length - 2);

  const chosen = () => {
    for (let r = 0; addedOrders > 0 && r < addedOrders.length; r++) {
      if (addedOrders[r].id === props.schoolP.id) {
        setDarkTheme(true);
      } else {
        return;
      }
    }
  };

  return (
    <Fragment>
      <Card className="wrapper" style={themeStyles}>
        <Card.Body>
          <div className="card-title-class">
            <Card.Title>{props.schoolP.title}</Card.Title>
            <Card.Title className="price-title">
              {preiseuro + "." + preiscent}
            </Card.Title>
          </div>
          <Card.Text>{props.schoolP.description}</Card.Text>

          <div className="card-unten">
            {props.adminPath || !props.orderavail ? (
              console.log("adminpath")
            ) : (
              <div className="bestellen-mit-popover">
                <div
                  className="bestellung-vormerken"
                  onClick={
                    addedOrders.includes(props.schoolP)
                      ? handleDel
                      : handleClick
                  }
                >
                  <strong>
                    <OrderComp schoolP={props.schoolP} />
                  </strong>
                </div>
                <OrderPopover menu={"menu"} />
              </div>
            )}
            <AllergeneComp schoolP={props.schoolP} />
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
}

export default SingleCardAdmin;
