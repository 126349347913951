import React, { useState } from "react";
import AddCategoryButton from "./AddCategoryButton";
import Card from "@material-ui/core/Card";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";

function AddInputCategory(props) {
  const [title, setTitle] = useState("");
  const [open, setOpen] = useState(false);
  let passPos = props.position;
  return !open ? (
    <div className="stand-alone-circle">
      <button onClick={() => setOpen(!open)}>
        <AddCircleIcon />{" "}
      </button>
    </div>
  ) : (
    <Card className="wrapper">
      <div className="flexbox-container">
        <button onClick={() => setOpen(!open)}>
          <CancelIcon />{" "}
        </button>
        <input
          placeholder="Kategorie"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <AddCategoryButton
          resetTitle={setTitle}
          tiltel={title}
          path={props.path}
          testAr={props.dasAr}
          position={passPos}
          catego={props.catego}
        />
      </div>
    </Card>
  );
}

export default AddInputCategory;
