import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import AppContext from "../auth/AppContext";

import CardsComp from "../components/CardsComp";

import axios from "axios";

axios.defaults.baseURL =
  "https://europe-west1-resg-f9e66.cloudfunctions.net/api";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },
}));

export default function TabComp(props) {
  const {
    tab,
    setTab,
    cardData,
    setCardData,
    setCategors,
    current,
  } = useContext(AppContext);

  const cateAmend = `categories${current}`;

  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(false);

  function getSchools2() {
    setLoading(true);
    axios.get(`menu/${props.path}`).then((res) => {
      console.log(res.data, "res.data[0].screamId", current);

      setCardData(res.data);
      console.log("setSchools", res.data[cateAmend]);
      setSchools(res.data);

      setLoading(false);
    });
  }

  useEffect(() => {
    getSchools2();
    // let deferredPrompt = "Hallohallo";

    // window.addEventListener("beforeinstallprompt", function (event) {
    //   console.log("[index] beforeinstallprompt fired");
    //   event.preventDefault();
    //   deferredPrompt = event;
    //   console.log(deferredPrompt);
    //   return false;
    // });
    // console.log("deferredPrompt", deferredPrompt);

    // eslint-disable-next-line
  }, []);

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    console.log("newValue", schools[newValue]);
    setTab(newValue);
    setCategors(schools[newValue]);
  };

  return (
    <div>
      <div className={classes.root}>
        <AppBar
          position="fixed"
          color="default"
          style={{
            background: "#ffcd3c",
            paddingTop: 10,
            paddingRight: 7,
            paddingLeft: 7,
            paddingBottom: 5,
          }}
        >
          <Typography variant="h6" className={classes.title}>
            {cardData.title}
          </Typography>

          <Tabs
            value={tab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {loading ? <Tab label="Lädt..." /> : null}
            {typeof schools[cateAmend] !== "undefined"
              ? schools[cateAmend].map((school, index) => (
                  <Tab key={index} label={school.name} {...a11yProps(index)} />
                ))
              : null}
          </Tabs>
        </AppBar>
        {typeof schools[cateAmend] !== "undefined"
          ? schools[cateAmend].map((school, i) => (
              <TabPanel key={i} value={tab} index={i}>
                <div className="top-space-div"></div>
                <CardsComp path={props.path} />
                <div className="bottom-space-div"></div>
              </TabPanel>
            ))
          : null}
      </div>
    </div>
  );
}
