import React, { useContext } from 'react'
import AppContext from '../auth/AppContext'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}))

export default function OrderPopover(props) {
  const { cardData, current } = useContext(AppContext)
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div className="order-pop">
      <HelpOutlineIcon onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {typeof cardData.languages !== 'undefined' &&
        (cardData.languages[current] === 'Deutsch' ||
          cardData.languages[current] === 'deutsch' ||
          cardData.languages[current] === 'German') ? (
          <Typography className={classes.typography}>
            {props.menu === 'menu'
              ? `Hier können Sie den Artikel für eine Bestellung vormerken. Wenn Sie
die Bestellung danach abschicken, wird es direkt für sie zubereitet
und Ihnen gebracht.`
              : `3-Stellige Zahl, die wir Ihnen gerne sagen. Oder Sie entnehmen die tagesaktuelle Zahl dem Aushang`}
          </Typography>
        ) : (
          <Typography className={classes.typography}>
            {props.menu === 'menu'
              ? `Hier können Sie den Artikel für eine Bestellung vormerken. Wenn Sie
die Bestellung danach abschicken, wird es direkt für sie zubereitet
und Ihnen gebracht.`
              : `3 digit number we are happy to tell you. Just ask us.`}
          </Typography>
        )}
      </Popover>
    </div>
  )
}
