import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import SingleChip from "./SingleChip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function ChipsComp(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.orderedos.map((school) => (
        <div key={school.createdAt}>
          <SingleChip
            uppi={school}
            filti={props.filti}
            catlist={props.catlist}
          />
        </div>
      ))}
    </div>
  );
}
