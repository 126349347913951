import React, { useState, useEffect } from "react";
import AppContext from "./AppContext";

const CoBspProvider = ({ children }) => {
  const [example, setExample] = useState({ title: "", added: "", id: "" }); //definitives TODO
  const [addedOrders, setAddedOrders] = useState([]);
  const [fetchComment, setFetchComment] = useState("initial");
  const [commentId, setCommentId] = useState("");
  const [commentMap, setCommentMap] = useState({ main: "hello" });
  const [cate, setCate] = useState({});
  const [tab, setTab] = useState(0);
  const [categors, setCategors] = useState([]);
  const [cardData, setCardData] = useState({});
  const [hasOrdered, setHasOrdered] = useState(false);
  const [countMap, setCountMap] = useState({ main: "countMap" });
  const [ordereds, setOrdereds] = useState([]);
  const [brColor, setBrColor] = useState(null);
  const [radio, setRadio] = useState("service");
  const [schoolsC, setSchoolsC] = useState(["start", "wzei"]);
  const [loadingC, setLoadingC] = useState(true);
  const [filteredAllL, setFilteredAllL] = useState(0);
  const [filters, setFilters] = useState("all");
  const [defPr, setDefPr] = useState(null);
  const [current, setCurrent] = useState(0);
  const [languageCount, setLanguageCount] = useState(0);

  const context = {
    setExample,
    example,
    addedOrders,
    setAddedOrders,
    commentId,
    setCommentId,
    commentMap,
    setCommentMap,
    setFetchComment,
    handleRemove,
    setCate,
    cate,
    tab,
    setTab,
    categors,
    setCategors,
    cardData,
    setCardData,
    hasOrdered,
    setHasOrdered,
    countMap,
    setCountMap,
    ordereds,
    setOrdereds,
    brColor,
    setBrColor,
    radio,
    setRadio,
    schoolsC,
    setSchoolsC,
    loadingC,
    setLoadingC,
    filteredAllL,
    setFilteredAllL,
    filters,
    setFilters,
    defPr,
    setDefPr,
    current,
    setCurrent,
    languageCount,
    setLanguageCount,
  };
  const newList = commentMap;

  function handleRemove(idprop) {
    console.log("Vor listdata", commentMap);

    delete newList[idprop];
    setCommentMap(newList);
    console.log("nach listdata", newList);
  }

  useEffect(() => {
    console.log("whatheret?", addedOrders, example);
    example.title !== "" && !addedOrders.includes(example)
      ? setAddedOrders((addedOrders) => [...addedOrders, example])
      : console.log("double add");

    console.log(addedOrders);
  }, [example]);

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

export default CoBspProvider;
