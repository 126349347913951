import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Form from "./components/Form";
// import Header from "./components/Header";
// import Auth from "./components/Auth";

// import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Location from "./pages/location";
// import UserPage from "./pages/userPage";
// import Login from "./pages/login";
// import Signup from "./pages/signup";
import Admin from "./pages/admin";
import Start from "./pages/start";
import OrdersComp from "./components/OrdersComp";
import ImpressumComp from "./pages/impressumComp";
import Kontaktverfolgung from "./pages/kontaktverfolgung";
import Datenschutz from "./pages/datenschutz";
import AGB from "./pages/agb";
import Prompt from "./components/Prompt";
// function App() {
//   let somethin = "new-request";
//   let addR = "add-request";

//   function handleClick(e) {
//     document.querySelector(".new-request").classList.add("open");
//   }

//   function closeModal(e) {
//     if (e.target.classList.contains("new-request")) {
//       document.querySelector(".new-request").classList.remove("open");
//     }
//   }

//   return (

//   );
// }

// export default App;

//
//import SnapshotFirebase from './SnapshotFirebase';
import { AuthProvider } from "./auth/AuthProv";
import { OrderProvider } from "./auth/OrderContext";
import CoBspProvider from "./auth/CoBsp";

function App() {
  //const get = false;

  return (
    <>
      <div className="App">
        <OrderProvider>
          <AuthProvider>
            <CoBspProvider>
              <Prompt />
              <Router>
                <Switch>
                  <Route exact path="/" component={Start} />
                  <Route exact path="/:userId" component={Location} />
                  <Route
                    exact
                    path="/:userId/datenschutzerklaerung"
                    component={Datenschutz}
                  />
                  <Route exact path="/:userId/agb" component={AGB} />
                  <Route exact path="/:userId/admin" component={Admin} />
                  <Route
                    exact
                    path="/:userId/impressum"
                    component={ImpressumComp}
                  />
                  <Route
                    exact
                    path="/:userId/bestellen"
                    component={OrdersComp}
                  />
                  <Route
                    exact
                    path="/:userId/kontaktverfolgung"
                    component={Kontaktverfolgung}
                  />
                  {/* <Route exact path="/:userId/:userId" component={UserPage} />
              <Route exact path="/:userId/:userId/login" component={Login} />
              <Route exact path="/:userId/:userId/signup" component={Signup} />
             */}
                </Switch>

                {/* <LoginComp />
            <GetFirebase /> */}
              </Router>
            </CoBspProvider>
          </AuthProvider>
        </OrderProvider>
      </div>
    </>
  );
}

export default App;
