import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import firebase from '../firebase'
import AppContext from '../auth/AppContext'

import CardsCompSnap from './CardsCompSnap'
import AddInputTop from './AddInputTop'
import AddInputCategory from './AddInputCategory'
import DeleteCategory from './DeleteCategory'
import ToggleOrderOption from './ToggleOrderOption'
import ZahlComp from './ZahlComp'
import OrdersAdmin from './orderadmin/OrdersAdmin'
import KontaktView from './KontaktView'
import KontaktReadView from './KontaktReadView'
import ToggleKontakt from './ToggleKontakt'
import ToggleKontaktAnzeige from './ToggleKontaktAnzeige'
import LanguageComp from './LanguageComp'
import LanguageAdminDrop from './LanguageAdminDrop'
import MakeTwo from './MakeTwo'

import Card from 'react-bootstrap/Card'
import 'bootstrap/dist/css/bootstrap.min.css'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
}))

export default function TabAdminComp(props) {
  const {
    tab,
    setTab,
    cardData,
    setCardData,
    setCategors,
    setSchoolsC,
    loadingC,
    setLoadingC,
    defPr,
    setDefPr,
    current,
  } = useContext(AppContext)

  const cateAmend = `categories${current}`

  function install() {
    if (!defPr) return
    defPr.prompt()

    defPr.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        // accepted
      } else {
        // dismissed
      }
      setDefPr(null)
    })
  }
  const askk = {
    categories0: [
      { filter: 'service', name: 'Lädt', orderoption: false },
      { filter: 'service', name: 'Loading', orderoption: false },
    ],
  }
  const [schools, setSchools] = useState(askk)

  const ref = firebase.firestore().collection(props.path).doc('docname')
  const refO = firebase.firestore().collection(props.path + '-orders')

  const date = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)
  useEffect(() => {
    const unsubscribeO = refO
      .where('crtd', '>', date)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          let myDataArray = []
          snapshot.forEach((doc) => myDataArray.push({ ...doc.data() }))
          setSchoolsC(myDataArray)
          // askk[cateAmend].map((school) => console.log(school.name));
          // console.log("date", cateAmend);
          setLoadingC(false)
        } else {
          console.log('emptyr')
          setLoadingC(false)
        }
      })

    const unsubscribe = ref.onSnapshot(function (doc) {
      setCardData(doc.data())
      // console.log("schaumer", doc.data(), cardData, cateAmend);
      setSchools(doc.data())
      setLoadingC(false)
    })

    return () => {
      unsubscribeO()
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    console.log('currrrent', current)
  }, [current])

  const schoolAmend = schools[cateAmend]

  const classes = useStyles()
  //const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    console.log('newValue', schoolAmend[newValue - 2])
    setTab(newValue)
    setCategors(schoolAmend[newValue - 2])
  }

  const themeStyles = {
    backgroundColor: null,

    padding: 0,
    margin: '1rem',
  }

  return !loadingC ? (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        color="default"
        style={{
          background: '#ffcd3c',
          paddingTop: 10,
          paddingRight: 7,
          paddingLeft: 7,
          paddingBottom: 5,
        }}
      >
        <Typography variant="h6" className={classes.title}>
          {cardData.title}
        </Typography>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab key={0} label="Kategorie + / - " {...a11yProps(0)} />
          <Tab key={1} label="Bestellungen" {...a11yProps(1)} />

          {schools[cateAmend].map((school, index) => (
            <Tab
              key={index + 2}
              label={school.name}
              {...a11yProps(index + 2)}
            />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel key="addCategory" value={tab} index={0}>
        <div className="top-space-div"></div>
        <div className="set-max-width">
          <ZahlComp path={props.path} />

          <LanguageComp path={props.path} />
          {/* <ToggleKontakt path={props.path} />
          <ToggleKontaktAnzeige path={props.path} /> */}
          <LanguageAdminDrop />

          <AddInputCategory
            path={props.path}
            position={0}
            catego={schools[cateAmend]}
          />

          <div>
            {schools[cateAmend].map((school, i) => (
              <div>
                {' '}
                <div className="category-edit-delete">
                  <Card className="wrapper" style={themeStyles}>
                    <Card.Body>
                      <div key={i}>
                        <div>
                          <p className="category-edit-tab">{school.name}</p>
                        </div>
                        <ToggleOrderOption path={props.path} school={school} />
                      </div>
                    </Card.Body>
                  </Card>
                  <div className="delete-btn-cardscompsnap">
                    <DeleteCategory path={props.path} catego={school} />
                  </div>
                </div>
                <AddInputCategory
                  path={props.path}
                  position={i + 1}
                  catego={schools[cateAmend]}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="bottom-space-div"></div>
      </TabPanel>
      <TabPanel key="bestellungen" value={tab} index={1}>
        <div className="top-space-div"></div>
        <div className="set-max-width">
          <OrdersAdmin path={props.path} />
        </div>
        <div className="bottom-space-div"></div>
      </TabPanel>{' '}
      <div className="set-max-width">
        {/* <TabPanel key="formulardaten" value={tab} index={schools.length + 2}>
        <div className="top-space-div"></div>
        {cardData.contactdisplay ? (
          <KontaktView path={props.path} />
        ) : (
          <KontaktReadView path={props.path} />
        )}
        <div className="bottom-space-div"></div>
      </TabPanel> */}
        {schools[cateAmend].map((school, i) => (
          <TabPanel key={i + 2} value={tab} index={i + 2}>
            <div className="top-space-div"></div>
            <AddInputTop
              path={props.path}
              position={0}
              catego={school.name}
              ganzprev="aaa"
            />

            <CardsCompSnap path={props.path} />
            <div className="bottom-space-div"></div>
          </TabPanel>
        ))}
      </div>
    </div>
  ) : (
    <p>Lädt...</p>
  )
}
