import React, { useContext, Fragment } from 'react'
import { Redirect } from 'react-router'

import { AuthContext } from '../auth/AuthProv'
import { useHistory } from 'react-router-dom'
import '../App.css'

import LoginComp from '../components/LoginComp'
import StartLogComp from '../components/StartLogComp'
import BottomNavRead from '../components/BottomNavRead'

import TabAdminComp from '../components/TabAdminComp'
import BottomNavigationAdminComp from '../components/BottomNavigationAdminComp'

function Start() {
  const { currentUser, isLogged } = useContext(AuthContext)

  const displayThis =
    currentUser.length < 1 ? (
      <StartLogComp />
    ) : (
      <Redirect to={`/${currentUser[0]}/admin`} />
    )
  const history = useHistory()
  function datenschutz() {
    history.push(`/test/datenschutzerklaerung`)
  }

  function impri() {
    history.push(`/test/impressum`)
  }

  return (
    <div className="set-max-width">
      {isLogged ? (
        displayThis
      ) : (
        <div>
          <StartLogComp />
          <div className="login-box ten-margin">
            <p>Willkommen!</p>
            <p>
              Sie sind Gast, und möchten die Karte sehen? Scannen Sie mit dem
              Smartphone den QR-Code an Ihrem Tisch.{' '}
            </p>
            <div className="welcome-abstand"></div>

            <p>Welcome!</p>
            <p>
              You're a guest and you want to see the menu? Scan your table's
              QR-code with your smartphone.
            </p>
            <p className="unterstrich" onClick={datenschutz}>
              Datenschutzerklärung
            </p>
            <p className="unterstrich" onClick={impri}>
              Impressum
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
export default Start
