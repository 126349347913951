import React, { useContext } from "react";
import AppContext from "../auth/AppContext";
import firebase from "../firebase";

import DeleteIcon from "@material-ui/icons/Delete";

function DeleteCategory(props) {
  const { current } = useContext(AppContext);
  const cateAmend = `categories${current}`;
  const ref = firebase.firestore().collection(props.path);
  const platzhalt = props.id;
  console.log(platzhalt);
  //DELETE FUNCTION
  function deleteSchool() {
    console.log(platzhalt);
    ref
      .doc("docname")
      .update({
        [cateAmend]: firebase.firestore.FieldValue.arrayRemove(props.catego),
      })
      .then(() => {
        console.log("DeletedCat");
        console.log(platzhalt);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <div>
      <DeleteIcon onClick={() => deleteSchool()} />
    </div>
  );
}

export default DeleteCategory;
