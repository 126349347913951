import React, { useState, useEffect } from "react";
import moment from "moment";

export default function OrderCompHeadline(props) {
  const fixedTime = moment(props.uppi.createdAt).fromNow();
  const [update, setUpdate] = useState(fixedTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setUpdate(fixedTime);
      console.log("updated", fixedTime);
    }, 10000);

    return () => clearInterval(intervalId); //This is important
  }, []);

  return (props.filti === "done" && props.uppi.status.includes("e")) ||
    (props.filti !== "done" && !props.uppi.status.includes("e")) ? (
    <div>
      <hr />
      <span className="category-edit-tab">
        Tisch {props.uppi.table} - {update}
      </span>
    </div>
  ) : (props.filti !== "done" && props.uppi.status.includes("z")) ||
    (props.filti !== "done" && props.uppi.status.includes("n")) ? (
    <div>
      <hr />
      <span>
        Tisch {props.uppi.table} - {update}
      </span>
    </div>
  ) : null;
}
