import React, { useState } from "react";
import firebase from "../firebase";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { v4 as uuidv4 } from "uuid";
import Checkbox from "@material-ui/core/Checkbox";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export default function KontaktVFormular(props) {
  const ref = firebase.firestore().collection(props.path + "-cotrace");
  const [tisch, setTisch] = useState("");
  const [vorname, setVorname] = useState("");
  const [name, setName] = useState("");
  const [strasse, setStrasse] = useState("");
  const [plz, setPlz] = useState("");
  const [ort, setOrt] = useState("");
  const [email, setEmail] = useState("");
  const [telefon, setTelefon] = useState("");
  const [checked, setChecked] = useState(false);
  const [submitPressed, setSubmitPressed] = useState(false);
  const [abschickText, setAbschickText] = useState("");

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const uid = uuidv4();
  function abschicken() {
    setSubmitPressed(true);
    if (
      tisch === "" ||
      vorname === "" ||
      name === "" ||
      strasse === "" ||
      plz === "" ||
      ort === "" ||
      email === "" ||
      telefon === ""
    ) {
      console.log("Angaben fehlen");
    } else if (checked === false) {
      console.log("bitte Haken setzen");
    } else {
      setSubmitPressed(false);
      ref
        .doc()
        .set({
          crtd: firebase.firestore.FieldValue.serverTimestamp(),
          date: new Date().toISOString(),
          id: uid,
          table: tisch,
          fname: vorname,
          lname: name,
          street: strasse,
          zip: plz,
          city: ort,
          emailadress: email,
          phone: telefon,
        })
        .then(() => {
          setTisch("");
          setVorname("");
          setName("");
          setStrasse("");
          setPlz("");
          setOrt("");
          setEmail("");
          setTelefon("");
          console.log("erledigtupdate");
          setAbschickText(vorname + " " + name);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  const classes = useStyles();

  return (
    <div>
      <Card className="wrapper">
        <div>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              id="standard-basic"
              label="Tischnummer"
              type="number"
              value={tisch}
              onChange={(e) => setTisch(e.target.value)}
              helperText={
                tisch === "" && submitPressed ? "Tischnummer fehlt" : null
              }
              error={tisch === "" && submitPressed}
            />
            <div className="flexbox-container">
              <TextField
                id="standard-basic"
                label="Vorname"
                type="text"
                value={vorname}
                onChange={(e) => setVorname(e.target.value)}
                helperText={
                  vorname === "" && submitPressed ? "Vorname fehlt" : null
                }
                error={vorname === "" && submitPressed}
              />
              <div className="space-euro"> </div>
              <div className="space-euro"> </div>
              <TextField
                id="standard-basic"
                label="Name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                helperText={
                  name === "" && submitPressed ? "Nachname fehlt" : null
                }
                error={name === "" && submitPressed}
              />
            </div>
            <TextField
              id="standard-basic"
              label="Straße und Hausnummer"
              type="text"
              value={strasse}
              onChange={(e) => setStrasse(e.target.value)}
              helperText={
                strasse === "" && submitPressed ? "Anschrift fehlt" : null
              }
              error={strasse === "" && submitPressed}
            />
            <br />
            <div className="flexbox-container">
              <TextField
                id="standard-basic"
                label="Postleitzahl"
                type="number"
                value={plz}
                onChange={(e) => setPlz(e.target.value)}
                helperText={
                  plz === "" && submitPressed ? "Postleitzahl fehlt" : null
                }
                error={plz === "" && submitPressed}
              />
              <div className="space-euro"> </div>
              <div className="space-euro"> </div>

              <TextField
                id="standard-basic"
                label="Ort"
                type="text"
                value={ort}
                onChange={(e) => setOrt(e.target.value)}
                helperText={
                  ort === "" && submitPressed ? "Bitte Ort angeben" : null
                }
                error={ort === "" && submitPressed}
              />
            </div>
            <TextField
              id="standard-basic"
              label="Email-adresse"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              helperText={
                email === "" && submitPressed ? "Gültige Email fehlt" : null
              }
              error={email === "" && submitPressed}
            />

            <br />
            <TextField
              id="standard-basic"
              label="Telefon/Mobiltelefon"
              type="number"
              value={telefon}
              onChange={(e) => setTelefon(e.target.value)}
              helperText={
                telefon === "" && submitPressed ? "Handynummer fehlt" : null
              }
              error={telefon === "" && submitPressed}
            />
            <br />
          </form>
          <div className="flexbox-container">
            <Checkbox
              checked={checked}
              onChange={handleChange}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <span className={submitPressed ? "fehler-rot" : null}>
              Die Bestimmungen zum Datenschutz (siehe unten) habe ich gelesen
              und akzeptiere ich.
            </span>
          </div>
          {abschickText !== "" ? (
            <span className="daten-gespeichert">
              Daten von {abschickText} erfolgreich gespeichert.
            </span>
          ) : null}
          <div>
            <button onClick={abschicken}>Abschicken</button>
          </div>
        </div>
      </Card>
    </div>
  );
}
