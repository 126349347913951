import React, { useContext } from "react";
import AssignmentIcon from "@material-ui/icons/Assignment";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import { useHistory } from "react-router-dom";
import AppContext from "../auth/AppContext";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function BottomNavigation(props) {
  const {
    addedOrders,
    cardData,
    languageCount,
    setLanguageCount,
    current,
    setCurrent,
  } = useContext(AppContext);
  const history = useHistory();

  function handleClick() {
    history.push(`/${props.path}/bestellen`);
  }

  function handleI() {
    history.push(`/${props.path}/impressum`);
  }

  function handleK() {
    history.push(`/${props.path}/kontaktverfolgung`);
  }

  function handleL() {
    console.log(current, cardData.languages.length);
    current === cardData.languages.length - 1
      ? setCurrent(0)
      : setCurrent(current + 1);
  }

  const lak = cardData !== {} ? cardData.languages : "Lädt";

  return (
    <div className="bottom-navigation">
      <div className="navi-icons">
        <div onClick={handleClick}>
          <Tab
            icon={
              <Badge badgeContent={addedOrders.length} color="secondary">
                <AssignmentIcon />
              </Badge>
            }
          />
        </div>
        {cardData.languageoption === true ? (
          <div onClick={handleL} className="flexbox-container">
            <span>
              {lak === "Lädt" || typeof lak === "undefined"
                ? lak
                : lak[current]}
            </span>{" "}
            <div>
              {" "}
              <ArrowForwardIosIcon fontSize="inherit" color="disabled" />
            </div>
          </div>
        ) : null}

        {/* {cardData.contactswitch ? (
          <div onClick={handleK}>
            <Tab label="Datenblatt" icon={<EditIcon />} />
          </div>
        ) : null} */}
        <div onClick={handleI}>
          <div className="bottom-beschriftung">
            <span>Impressum</span>
            <br />
            <span>Datenschutz</span>
            <br />
            <span>AGB</span>
          </div>
        </div>
      </div>
    </div>
  );
}
