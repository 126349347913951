import React, { useContext } from 'react'
import AppContext from '../auth/AppContext'

function OrderComp(props) {
  const { addedOrders, cardData, current } = useContext(AppContext)
  const welcherZustand = addedOrders.includes(props.schoolP)

  return (
    <div className="vormerken-button">
      {cardData.languages[current] === 'Deutsch' ||
      cardData.languages[current] === 'deutsch' ||
      cardData.languages[current] === 'German' ? (
        <span>
          {welcherZustand
            ? 'Vormerken rückgängig machen'
            : 'Vormerken für Bestellung'}
        </span>
      ) : (
        <span>{welcherZustand ? 'Remove from list' : 'Add to list'}</span>
      )}
    </div>
  )
}

export default OrderComp
