import React, { useContext } from "react";
import Badge from "@material-ui/core/Badge";
import AppContext from "../../auth/AppContext";

export default function OrdersFilter(props) {
  const { filters, setFilters } = useContext(AppContext);
  console.log(filters);

  return (
    <div className="orders-filter">
      <div className="card-title-class">
        <span
          onClick={() => setFilters("all")}
          className={filters === "all" ? "filter-underlined" : ""}
        >
          <Badge badgeContent={props.filterall} color="secondary">
            Alle
          </Badge>
        </span>
        <span
          onClick={() => setFilters("service")}
          className={filters === "service" ? "filter-underlined" : ""}
        >
          <Badge badgeContent={props.filterservice} color="secondary">
            Service
          </Badge>
        </span>
        <span
          onClick={() => setFilters("kitchen")}
          className={filters === "kitchen" ? "filter-underlined" : ""}
        >
          <Badge badgeContent={props.filterkitchen} color="secondary">
            Küche
          </Badge>
        </span>
        <span
          onClick={() => setFilters("done")}
          className={filters === "done" ? "filter-underlined" : ""}
        >
          Erledigt
        </span>
      </div>
    </div>
  );
}

// import React, { useContext } from "react";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// import FastfoodIcon from "@material-ui/icons/Fastfood";
// import { makeStyles } from "@material-ui/core/styles";
// import Badge from "@material-ui/core/Badge";

// import firebase from "./../firebase";
// import AppContext from "../auth/AppContext";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& > *": {
//       margin: theme.spacing(1),
//     },
//   },
// }));

// export default function BottomNavigationAdminComp() {
//   const { setTab, cardData } = useContext(AppContext);
//   const logOut = () => {
//     firebase.auth().signOut();
//   };
//   const classes = useStyles();
//   return (
//     <div className="bottom-navigation">
//       <div className="navi-icons">
//         <Badge badgeContent={7} color="secondary">
//           <FastfoodIcon
//             fontSize="large"
//             onClick={() => setTab(cardData.categories.length + 1)}
//           />
//         </Badge>

//         <ExitToAppIcon fontSize="large" onClick={logOut} />
//       </div>
//     </div>
//   );
// }
