import React, { useContext } from "react";
import firebase from "../firebase";

import AppContext from "../auth/AppContext";

// ADD FUNCTION
function AddCategoryButton(props) {
  const { cardData, current } = useContext(AppContext);
  const { resetTitle } = props;

  const ref = firebase.firestore().collection(`${props.path}`);
  const cateAmend = `categories${current}`;
  // ADD FUNCTION
  function addSchool(newSchool) {
    const insert = (arr, index, newItem) => [
      // part of the array before the specified index
      ...arr.slice(0, index),
      // inserted item
      newItem,
      // part of the array after the specified index
      ...arr.slice(index),
    ];

    ref
      .doc("docname")
      .get()
      .then((doc) => {
        const result = insert(cardData[cateAmend], props.position, newSchool);
        ref
          .doc("docname")
          .update({ [cateAmend]: result })
          .then(() => {
            console.log("Erfolgreich gespeichert!");
            resetTitle("");
          })
          .catch((err) => {
            console.error(err);
          });
      });
  }

  return (
    <div>
      <button
        onClick={
          props.tiltel !== ""
            ? () =>
                addSchool({
                  name: props.tiltel,
                  orderoption: false,
                  filter: "service",
                })
            : null
        }
      >
        Submit
      </button>
    </div>
  );
}

export default AddCategoryButton;
