import React, { useContext, useEffect } from "react";
import AppContext from "../auth/AppContext";

import DeleteIcon from "@material-ui/icons/Delete";

function DeleteComp(props) {
  const { addedOrders, setAddedOrders } = useContext(AppContext);
  let arr = addedOrders;
  let filtered = [];
  function removeVal() {
    filtered = arr.filter(function (value, index, arr) {
      return value !== props.objDelete;
    });

    setAddedOrders(filtered);
  }

  return (
    <div className="remove-farbe">
      <DeleteIcon onClick={removeVal} color="inherit" />
    </div>
  );
}

export default DeleteComp;
