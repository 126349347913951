import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { useHistory } from "react-router-dom";

function Navi(props) {
  const history = useHistory();
  function handleClick() {
    history.push(props.path);
  }
  function clickSpeisekarte() {
    history.push(props.clickSpeise);
  }
  return (
    <div className="navbar-div">
      <Navbar.Brand>Navbarii</Navbar.Brand>
      <Navbar.Text onClick={handleClick}>Bestellungen</Navbar.Text>
      <Navbar.Text onClick={clickSpeisekarte}>Speisekarte</Navbar.Text>
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>Signed in as: Mark Otto</Navbar.Text>
      </Navbar.Collapse>
    </div>
  );
}

export default Navi;
