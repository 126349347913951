import React, { useState } from "react";
import AddButton from "./AddButton";
import Card from "@material-ui/core/Card";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function AddInput(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [euro, setEuro] = useState(0);
  const [cent, setCent] = useState(0);
  const [allergene, setAllergene] = useState("");
  const [open, setOpen] = useState(false);
  const [submitPressed, setSubmitPressed] = useState(false);
  let passPos = props.position;
  const classes = useStyles();
  return !open ? (
    <div className="stand-alone-circle">
      <button onClick={() => setOpen(!open)}>
        <AddCircleIcon />{" "}
      </button>
    </div>
  ) : (
    <Card className="wrapper">
      <div>
        <button onClick={() => setOpen(!open)}>
          <CancelIcon />{" "}
        </button>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            id="standard-basic"
            label="Titel"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            helperText={title === "" && submitPressed ? "Eingabe fehlt" : null}
            error={title === "" && submitPressed}
          />
          <br />

          <div className="flexbox-container">
            <TextField
              id="standard-basic"
              label="Preis: Euro"
              type="number"
              value={euro}
              onChange={(e) => setEuro(e.target.value)}
              helperText={
                euro.toString().length > 3 && submitPressed
                  ? "maximal 3-stellig"
                  : null
              }
              error={euro.toString().length > 3 && submitPressed}
            />
            <div className="space-euro"> </div> <h1> , </h1>{" "}
            <div className="space-euro"> </div>
            <TextField
              id="standard-basic"
              label="Cent"
              type="number"
              value={cent}
              onChange={(e) => setCent(e.target.value)}
              helperText={
                cent.toString().length > 2 && submitPressed
                  ? "maximal 2-stellig"
                  : null
              }
              error={cent.toString().length > 2 && submitPressed}
            />
          </div>

          <br />
          <TextField
            id="standard-basic"
            label="(Optional) Beschreibung"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <br />
          <TextField
            id="standard-basic"
            label="(Optional) Allergene"
            value={allergene}
            onChange={(e) => setAllergene(e.target.value)}
          />
          <br />
        </form>
        <AddButton
          setsubmit={setSubmitPressed}
          resetTitle={setTitle}
          tiltel={title}
          path={props.path}
          testAr={props.dasAr}
          position={passPos}
          catego={props.catego}
          ganzprev={props.prevpos.id}
          close={setOpen}
          open={open}
          resetdescription={setDescription}
          description={description}
          reseteuro={setEuro}
          euro={euro}
          resetcent={setCent}
          cent={cent}
          resetallergene={setAllergene}
          allergene={allergene}
        />
      </div>
    </Card>
  );
}

export default AddInput;
