import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBwjWHTMMVzDsZClgaWRLdWTkdGICyxNJI",
  authDomain: "resg-f9e66.firebaseapp.com",
  databaseURL: "https://resg-f9e66.firebaseio.com",
  projectId: "resg-f9e66",
  storageBucket: "resg-f9e66.appspot.com",
  messagingSenderId: "149333895620",
  appId: "1:149333895620:web:af8af20b129d9e0c675c91",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
