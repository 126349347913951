import React, { useState, useContext } from "react";
import AppContext from "../auth/AppContext";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import TextField from "@material-ui/core/TextField";
import CancelIcon from "@material-ui/icons/Cancel";

import firebase from "../firebase";

export default function LanguageComp(props) {
  const ref = firebase.firestore().collection(props.path).doc("docname");
  const { cardData, current, setCurrent } = useContext(AppContext);

  const [neue, setNeue] = useState("");

  const handleChange = (event) => {
    console.log("Language statts", cardData.languageoption);
    let toggleVal = event.target.checked;
    console.log("toggleVal", toggleVal);
    ref.update({ languageoption: toggleVal }).then(() => {
      console.log("erledigt", toggleVal);
      setCurrent(0);
    });
  };

  function handleCancel(value, index) {
    ref.get().then((doc) => {
      const cateOther = `categories${current}`;
      let fuuttt = {};
      console.log(doc.data().languages);
      let docL = doc.data().languages;
      for (let i = index + 1; i < docL.length; i++) {
        fuuttt[`categories${i - 1}`] = doc.data()[`categories${i}`];
      }
      fuuttt[`categories${docL.length - 1}`] = [
        { filter: "service", name: "Getränke", orderoption: false },
      ];
      ref.update(fuuttt).then(
        ref
          .update({
            languages: firebase.firestore.FieldValue.arrayRemove(value),
          })
          .then(console.log("fuuttt", fuuttt), setCurrent(0))
      );
    });
  }

  function handleClick() {
    ref
      .update({
        languages: firebase.firestore.FieldValue.arrayUnion(neue),
      })
      .then(setNeue(""));
  }

  return (
    <div>
      <span>Mehrere Sprachen / Languages</span>

      <div>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              control={<Switch color="primary" />}
              checked={cardData.languageoption ? true : false}
              label={cardData.languageoption ? "Aktiv" : "Inaktiv"}
              labelPlacement="end"
              onChange={handleChange}
            />
          </FormGroup>
        </FormControl>
        <div>
          {cardData.languageoption ? (
            <div>
              {cardData.languages.map((school, index) => (
                <div>
                  <div
                    className="flexbox-container styling-languages"
                    key={index}
                  >
                    <span>{school}</span>
                    {cardData.languages.length > 1 && index !== 0 ? (
                      <div className="cancel-icon">
                        <CancelIcon
                          onClick={() => handleCancel(school, index)}
                        />
                      </div>
                    ) : null}{" "}
                  </div>
                  <div className="language-abstand"></div>
                </div>
              ))}

              {cardData.languages.length < 5 ? (
                <div>
                  <TextField
                    id="standard-zwei"
                    label="Sprache"
                    value={neue}
                    onChange={(e) => setNeue(e.target.value)}
                    variant="outlined"
                  />
                  <button className="zahl-button" onClick={handleClick}>
                    Speichern
                  </button>{" "}
                </div>
              ) : (
                <p className="bottom-beschriftung">
                  Es können maximal 5 Sprachen hinzugefügt werden
                </p>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <hr />
    </div>
  );
}
