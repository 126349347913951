import React, { useState, useContext } from "react";
import AppContext from "../auth/AppContext";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

export default function LanguageAdminDrop() {
  const { cardData, current, setCurrent } = useContext(AppContext);
  const [open, setOpen] = useState(false);

  function handleClick(index) {
    setCurrent(index);
    setOpen(!open);
  }

  return cardData.languageoption === true ? (
    <div className="flexbox-container">
      <div className="language-wahl">
        <span onClick={() => setOpen(!open)}>
          {cardData.languages.length > current
            ? cardData.languages[current]
            : "Sprache wählen"}
        </span>
        {!open ? (
          <ArrowForwardIosIcon fontSize="inherit" color="disabled" />
        ) : null}
      </div>
      {open ? (
        <div className="dropdown-div">
          {cardData.languages.map((school, index) => (
            <div key={index}>
              <span onClick={() => handleClick(index)}>{school}</span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  ) : null;
}
