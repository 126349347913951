import React, { Fragment, useContext } from "react";

import AddInput from "./AddInput";
import DeleteComp from "./DeleteComp";

import AppContext from "../auth/AppContext";
import SingleCardAdmin from "./SingleCardAdmin";

function CardsCompSnap(props) {
  const { tab, categors, cardData, loadingC, current } = useContext(AppContext);
  const cateAmend = `categories${current}`;

  let newArray = cardData.item.filter(function (el) {
    if (categors === []) {
      return el.category === cardData[cateAmend][0].name;
    } else {
      return el.category === cardData[cateAmend][tab - 2].name;
    }
  });
  return (
    <Fragment>
      {newArray.map((school, index) => (
        <div key={school.id}>
          <div className="flexbox-container">
            <SingleCardAdmin
              loading={loadingC ? <h1>Loading...</h1> : null}
              schoolP={school}
              adminPath={true}
              adminPathName={props.path}
            />
            <div className="delete-btn-cardscompsnap">
              {" "}
              <DeleteComp
                title={school.title}
                path={props.path}
                id={school.id}
                adde={school.added}
                catego={school.category}
                price={school.price}
                description={school.description}
                allergene={school.allergene}
              />
            </div>
          </div>
          <AddInput
            path={props.path}
            position={index + 1}
            catego={school.category}
            prevpos={school}
          />
        </div>
      ))}
    </Fragment>
  );
}

export default CardsCompSnap;
