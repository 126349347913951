import React, { useContext, Fragment } from "react";

import AppContext from "../auth/AppContext";
import AppBar from "@material-ui/core/AppBar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import KontaktVFormular from "../components/KontaktVFormular";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";

export default function Kontaktverfolgung({ location }) {
  const useStyles = makeStyles((theme) => ({
    title: {
      flexGrow: 1,
      textAlign: "center",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const { addedOrders, hasOrdered } = useContext(AppContext);
  const getPathname = location.pathname.split("/")[1];
  const history = useHistory();
  function handleClick() {
    history.push(`/${location.pathname.split("/")[1]}`);
  }
  return (
    <div>
      <AppBar
        position="fixed"
        color="default"
        style={{ background: "#ffcd3c" }}
      >
        <Toolbar>
          <ArrowBackIcon onClick={handleClick} className={classes.menuButton} />
          <Typography variant="h6" className={classes.title}>
            Covid-19-Kontaktverfolgung
          </Typography>
        </Toolbar>
      </AppBar>
      <div className="top-space-div"></div>
      <div className="seven-abstand"></div>
      <KontaktVFormular path={getPathname} />
      <p className="order-fehler-p">
        Wenn Sie wollen, können Sie nach dem Abschicken die Daten weiterer
        Personen eingeben und abschicken.
      </p>
    </div>
  );
}
